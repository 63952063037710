// This tiny wrapper for localStorage and sessionStorage prevents exceptions thrown by the
// browser when Content Settings prevents from reading or setting any data.
// If an exception occurs inside this wrapper, it will fall back to use in-memory storage;
// allowing the user to log in (set jwt), interact with the product normally but all that data, including the jwt
// will not be persisted and lost if the user reloads the page.
//
// Please DO NOT DISABLE `no-storage/no-browser-storage`, instead use:
//   storage.local
//   storage.session
//
// If you try to access the raw Storage APIs from the window object without try/catching you run the risk
// of causing a White Screen Of Death

export interface StorageFacade {
  local: Storage
  session: Storage
}

const local = storageFactory(() => window.localStorage)

const session = storageFactory(() => window.sessionStorage)

export const storage: StorageFacade = Object.freeze({ local, session })

// Storage wrapper with in-memory fallback
export function storageFactory(getStorage: () => Storage): Storage {
  const inMemory = createInMemoryStorage()

  return Object.freeze({
    clear() {
      try {
        getStorage().clear()
      } catch {
        inMemory.clear()
      }
    },

    getItem(name: string) {
      try {
        return getStorage().getItem(name)
      } catch {
        return inMemory.getItem(name)
      }
    },

    key(index: number) {
      try {
        return getStorage().key(index)
      } catch {
        return inMemory.key(index)
      }
    },

    removeItem(name: string) {
      try {
        getStorage().removeItem(name)
      } catch {
        inMemory.removeItem(name)
      }
    },

    setItem(name: string, value: string) {
      try {
        getStorage().setItem(name, value)
      } catch {
        inMemory.setItem(name, value)
      }
    },

    get length() {
      try {
        return getStorage().length
      } catch {
        return inMemory.length
      }
    },
  })
}

export function createInMemoryStorage(): Storage {
  const inMemoryStorage = new Map<string, string>()

  return Object.freeze({
    clear() {
      inMemoryStorage.clear()
    },

    getItem(name: string) {
      return inMemoryStorage.get(name) || null
    },

    key(index: number) {
      return Array.from(inMemoryStorage.keys())[index] ?? null
    },

    removeItem(name: string) {
      inMemoryStorage.delete(name)
    },

    setItem(name: string, value: string) {
      inMemoryStorage.set(name, value)
    },

    get length() {
      return inMemoryStorage.size
    },
  })
}
