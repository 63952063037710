import * as React from "react"
import { useApolloClient } from "@apollo/client"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import Session, { type SessionLogoutState } from "@digits-shared/session/Session"

export function useSessionListeners(loginPath: string) {
  const session = useSession()
  const apolloClient = useApolloClient()
  const { history } = useRouter()
  const [sessionTokenUpdateAt, setTokenUpdatedAt] = React.useState<number>(Date.now())

  const handleSessionChange = React.useCallback(() => {
    setTokenUpdatedAt(Date.now())
  }, [])

  const handleSessionLogout = React.useCallback(
    async (state?: SessionLogoutState) => {
      const { location } = history
      if (state?.code === GRPCErrorCode.Unauthenticated && !state.from && !location.state?.from) {
        state.from = location
      } else if (state && location.state?.from) {
        // Pass through the location state `from` field in the last state
        // so it can be used in a redirect after login correctly.
        state.from = location.state.from
      }

      // Check whether the location wants to avoid redirecting away
      if (!location.state?.skipLogoutRedirect) {
        history.replace(loginPath, state)
      }

      // Clear apollo client store
      // Use clearStore to avoid refetching queries
      // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
      await apolloClient.clearStore()
    },
    [apolloClient, history, loginPath]
  )

  React.useEffect(() => {
    session.on(Session.CREATE_EVENT_NAMESPACE, handleSessionChange)
    session.on(Session.UPDATE_EVENT_NAMESPACE, handleSessionChange)
    session.on(Session.DELETE_EVENT_NAMESPACE, handleSessionChange)
    session.on(Session.JWT_DELETE_EVENT_NAME, handleSessionLogout)
    window.addEventListener("storage", session.handleLocalStorageUpdated)

    return () => {
      session.off(Session.CREATE_EVENT_NAMESPACE, handleSessionChange)
      session.off(Session.UPDATE_EVENT_NAMESPACE, handleSessionChange)
      session.off(Session.DELETE_EVENT_NAMESPACE, handleSessionChange)
      session.off(Session.JWT_DELETE_EVENT_NAME, handleSessionLogout)
      window.removeEventListener("storage", session.handleLocalStorageUpdated)
    }
  }, [handleSessionChange, handleSessionLogout, session])

  return sessionTokenUpdateAt
}
