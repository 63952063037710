import { type Transaction } from "@digits-graphql/frontend/graphql-bearer"

export const transactionsWithSummaries = {
  TransactionsWithSummaries: {
    fields: {
      transactions: {
        merge(existing: Transaction[] = [], incoming: Transaction[] = []) {
          return [...existing, ...incoming]
        },
      },
    },
  },
}
