import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { GenericLoading, LogoLoading } from "./Page"

export const ModuleLoadingStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${colors.secondary};
`

export const ModuleLoading: React.FC<{ className?: string }> = ({ className }) => (
  <ModuleLoadingStyled className={className}>
    <LogoLoading size="60px" />
  </ModuleLoadingStyled>
)

export const GenericModuleLoading: React.FC = () => (
  <ModuleLoadingStyled>
    <GenericLoading size="60px" />
  </ModuleLoadingStyled>
)

export const SpringboardModuleLoading: React.FC<{ className?: string }> = ({ className }) => (
  <ModuleLoadingStyled className={className}>
    <LogoLoading size="60px" />
  </ModuleLoadingStyled>
)
