import * as React from "react"
import { off, on } from "react-use/lib/misc/util"
import envHelper from "@digits-shared/helpers/envHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"

interface Coordinates {
  x: number
  y: number
}

// calls `onClick` function when a click happens outside the `ref` element
export function useOnBodyClick(
  ref: React.RefObject<HTMLElement | EventTarget | null>,
  onClick: (event: MouseEvent) => void,
  ignore = false,
  mouseTracking = true
) {
  const startCoordinates = React.useRef<Coordinates>()

  // Save where the click started
  const handleDocumentMouseDown = React.useCallback(
    (event: MouseEvent) => {
      if (!mouseTracking || !ref?.current || !wasClickInside(ref.current, event)) {
        startCoordinates.current = undefined
        return
      }
      startCoordinates.current = {
        x: event.clientX,
        y: event.clientY,
      }
    },
    [mouseTracking, ref]
  )

  const handleDocumentClick = React.useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return

      if (!wasClickInside(ref.current, event, startCoordinates.current)) {
        if (envHelper.isDevelopment()) {
          // eslint-disable-next-line no-console
          console.debug("document click, attached by", ref.current)
        }

        startCoordinates.current = undefined
        // do not preventDefault or stopPropagation here,
        // if you need to do it, do it inside your callback
        onClick(event)
      }
    },
    [ref, onClick]
  )

  React.useEffect(() => {
    const options = { capture: true }
    off(document, "mousedown", handleDocumentMouseDown, options)
    off(document, "click", handleDocumentClick, options)

    if (!ignore) {
      on(document, "mousedown", handleDocumentMouseDown, options)
      on(document, "click", handleDocumentClick, options)
    }
    return () => {
      off(document, "mousedown", handleDocumentMouseDown, options)
      off(document, "click", handleDocumentClick, options)
    }
  }, [handleDocumentClick, handleDocumentMouseDown, ignore])
}

function wasClickInside(
  el: HTMLElement | EventTarget | null,
  event: MouseEvent,
  startCoordinates?: Coordinates
) {
  if (startCoordinates) {
    const endCoordinates: Coordinates = {
      x: event.clientX,
      y: event.clientY,
    }
    if (!objectHelper.deepEqual(startCoordinates, endCoordinates)) {
      return true
    }
  }

  let target = event?.target as HTMLElement

  // Break early if the event target is our element
  if (event?.target === el) return true

  while (target && target !== el) {
    target = target.parentElement as HTMLElement
  }

  return !!target
}
