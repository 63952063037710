import {
  type PeriodInsights,
  type QueryListInsightsArgs,
} from "@digits-graphql/frontend/graphql-bearer"
import { intervalOriginPagination } from "src/shared/initializers/typePolicies/helpers"

export const listInsights = intervalOriginPagination<QueryListInsightsArgs, PeriodInsights>(
  (list, newInsight) =>
    !!list.find((insight) => insight.period.startedAt === newInsight.period.startedAt)
)
