export const transaction = {
  Transaction: {
    keyFields: ["viewId", "displayEntry"],
    fields: {
      supersededFactIds: {
        merge: (existing: string[], incoming: string[]) => {
          if (!existing || !existing.length) return incoming

          const merged = existing.slice()
          const existingById = new Map<string, string>()
          merged.reduce(
            (existingMap, nextExisting) => existingMap.set(nextExisting, nextExisting),
            existingById
          )

          incoming.forEach(
            (nextIncoming) => existingById.has(nextIncoming) && merged.push(nextIncoming)
          )

          return merged
        },
      },
    },
  },
}
