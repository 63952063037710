export function defined<Data>(data?: Data | null): data is Data {
  return !!data || data === 0
}

export function notNull<Data>(data: Data | null): data is Data {
  return data !== null
}

export function unique<Data>(data: Data, index: number, arr: Data[]) {
  return index === arr.indexOf(data)
}

export function uniqueBy<Data>(selector: (item: Data) => unknown) {
  const uniqueSelectors = new Set()
  return (data: Data) => {
    const sel = selector(data)
    if (!uniqueSelectors.has(sel)) {
      uniqueSelectors.add(sel)
      return true
    }
    return false
  }
}
