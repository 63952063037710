export interface JWTPermissions {
  // key is the module name
  [key: string]: number
}

// JWTPermissionFlag is an enum that maps to
// permission flag values used elsewhere.
//
// This must always align with the permission
// flag values in go-services/shared/auth/permission/
//
// Currently these values are flag bit-masks.
export enum JWTPermissionFlag {
  Read = 1,
  Create = 2,
  Update = 4,
  Delete = 8,
  Sensitive = 16,
  Grant = 32,
  Comment = 64,
}

export type JWTPermissionMode = number
