// Create a ReadableStream from Base64-encoded string
export const readableStreamFromBase64 = (b64Data: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays: Uint8Array[] = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  // Happy path (Chrome or newer Safari), use Native APIs
  const blob = new Blob(byteArrays)
  if ("stream" in blob) {
    // check if current browser supports .pipeThrough, if not, use ponyfill
    const nativeStream = blob.stream()
    if ("pipeThrough" in nativeStream) {
      return nativeStream as unknown as ReadableStream<Uint8Array>
    }
  }

  // if native Stream API does not have blob.stream() or stream.pipeThrough
  // return native or ponyfill implementation to read the bytes
  return readableStreamFromArray(byteArrays)
}

// Create a ReadableStream from a Byte Array compatible with DecompressionStream.
export function readableStreamFromArray<T>(byteArrays: T[]): ReadableStream<T> {
  const it = byteArrays[Symbol.iterator]()

  return new ReadableStream<T>(
    {
      pull(controller) {
        const { done, value } = it.next()
        if (done) {
          controller.close()
        } else {
          controller.enqueue(value)
        }
      },
      cancel(reason) {
        TrackJS?.track(reason)
        it.throw?.(reason)
      },
    },
    { highWaterMark: 0 }
  )
}
