export const userPreferenceKeys = {
  animationConfigDevTools: "acdt",
  reportFileSourceDevTools: "rfsdt",
  gridDevTools: "gdt",
  hideInviteAccountantToast: "hide-invite-accountant-toast",
  viewedAssistant: "viewed-assistant",
  reportDocViewer: "rdv",
  vendorDirectory: "vendor-directory",
  graphqlNoBatching: "graphql-no-batching",
  actionItemAssignments: "action-item-assignments",
}
