export function convertEnumKeysToArray<E extends object>(e: E) {
  return Object.keys(e) as Array<keyof E>
}

export function convertEnumValuesToArray<E extends object>(e: E) {
  return Object.values(e) as Array<E[keyof E]>
}

/** Returns the NonNullable type based on a truthiness test */
export function exists<T>(value: T): value is NonNullable<T> {
  return Boolean(value)
}
