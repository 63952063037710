import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { ModuleLoading } from "@digits-shared/components/Loaders"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { useSessionListeners } from "@digits-shared/session/useSessionListeners"
import { AdminBearerRefresh } from "src/admin/components/Session/AdminBearerRefresh"
import LogOut from "src/admin/components/Session/LogOut"
import routes from "src/admin/routes"
import ClientVersionTracker from "src/shared/components/Background/ClientVersionTracker"
import { TimeContextURLProvider } from "src/shared/components/Contexts/TimeContext"

/*
  COMPONENTS
*/

const LogIn = React.lazy(() => import("./Session/LogIn"))

const Home = React.lazy(() => import("./Home/Home"))

/**
 * Top-level app component for the admin portal
 */
export const AdminClient: React.FC = () => {
  useSessionListeners(routes.login.parameterizedPath)

  return (
    <TimeContextURLProvider routes={routes}>
      <AdminBearerRefresh />
      <ClientVersionTracker />
      <React.Suspense fallback={<ModuleLoading />}>
        <Switch>
          <Route exact path={routes.login.parameterizedPath} component={LogIn} />

          <Route
            exact
            path={routes.logout.parameterizedPath}
            render={() => (
              <ProtectedRoute component={LogOut} redirectTo={routes.login.parameterizedPath} />
            )}
          />

          <Route
            path="*"
            render={() => (
              <ProtectedRoute component={Home} redirectTo={routes.login.parameterizedPath} />
            )}
          />
        </Switch>
      </React.Suspense>
    </TimeContextURLProvider>
  )
}
