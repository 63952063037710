import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

const purple = "rgba(120, 98, 255, 0.45)" as const
const blue = "rgba(64, 221, 255, 0.85)" as const
const green = "rgba(78, 255, 170, 0.55)" as const

const HALO_WIDTH = 457
const HALO_HEIGHT = 412

type Gradient = {
  top: number
  left: number
  size: number
  color: string
}

type GradientPair = {
  top: number
  left: number
  big: string
  small: string
}

function rg({ top, left, size, color }: Gradient) {
  return `
radial-gradient(
  circle at calc(50% + ${-HALO_WIDTH / 2 + size / 2 + left}px) calc(50% + ${-HALO_HEIGHT / 2 + size / 2 + top}px),
  ${color} ${Math.max(size / 2, 0)}px,
  ${colors.transparent} ${size / 2}px
)
`
}

function rgs(gradients: Gradient[]) {
  return gradients.map(rg).join(",")
}

function rgPair({ top, left, big, small }: GradientPair) {
  return `${rgs([
    { top, left, color: big, size: 257 },
    { top: top + 151, left: left + 157, color: small, size: 128 },
  ])}`
}

function rgPairs(pairs: GradientPair[]) {
  return pairs.map(rgPair).join(",")
}

const topLeft: GradientPair = { top: 0, left: 0, big: purple, small: blue }
const topRight: GradientPair = { top: 24.5, left: 172, big: purple, small: blue }
const bottomRight: GradientPair = { top: 102, left: 120, big: green, small: blue }
const bottomLeft: GradientPair = { top: 132, left: 23, big: green, small: blue }

const gradientPairs: GradientPair[] = [topLeft, topRight, bottomRight, bottomLeft]

export const GRADIENT_HALO = css`
  background-color: #f4f7fd;
  background: ${rgPairs(gradientPairs)};
  --module-loading-color: ${colors.white};
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
    backdrop-filter: blur(72px);
  }
`
