import { type FieldFunctionOptions } from "@apollo/client"
import { type Pagination } from "@digits-graphql/frontend/graphql-bearer"
import { type ExistsFn } from "src/shared/initializers/typePolicies/helpers/mergeReducers"
import { allKeyArgs } from "./allKeyArgs"

// Do not key off pagination fields.
// We want to cache responses by all the filters except these in order to support infinite scroll
const EXCLUDED_KEYS = ["pagination", "page"]

interface PaginationKey {
  pagination: Pagination
}

interface OptionalPaginationKey {
  pagination?: Pagination | null
}

interface PageKey {
  page: Pagination
}

type PaginationVars = PaginationKey | PageKey | OptionalPaginationKey

const paginationKeyArgs = (args: PaginationVars) => allKeyArgs(args, EXCLUDED_KEYS)

export function offsetLimitPagination<
  TVars extends PaginationVars = PaginationVars,
  TData = object,
>(exists?: ExistsFn<TData>) {
  return {
    keyArgs: paginationKeyArgs,
    merge(existing: TData[], incoming: TData[], options: FieldFunctionOptions<TVars>) {
      const { args } = options
      // not an array, unsupported (you have to do your own merge function)
      if (!existing?.slice) {
        return incoming
      }

      // new copy of the existing values
      const merged = existing.slice(0)

      // if offset is not present, or 0 it will return only the incoming values (to avoid dupes)
      const start = isPagination(args)
        ? args.pagination.offset
        : isPage(args)
          ? args.page.offset
          : 0

      const end = start + incoming.length
      for (let i = start; i < end; i += 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newElem = incoming[i - start]!
        const existsInList = exists ? exists(merged, newElem) : false

        if (!existsInList) {
          merged[i] = newElem
        }
      }

      return merged
    },
  }
}

const isPagination = (args: PaginationVars | null): args is PaginationKey =>
  !!(args as PaginationKey)?.pagination

const isPage = (args: PaginationVars | null): args is PageKey => !!(args as PageKey)?.page
