import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronRight } from "@digits-shared/components/SVGIcons/line/ChevronRight.svg"
import { type DigitsThemeProps, themedValue, type ThemeValues } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css, type RuleSet } from "styled-components"

export enum PointingDirection {
  Up = "Up",
  Right = "Right",
  Down = "Down",
  Left = "Left",
  None = "None",
  DownLeft = "DownLeft",
  UpLeft = "UpLeft",
  UpRight = "UpRight",
}

export function themedChevronStyles<P = object>(
  pointingDirectionArg: PointingDirection | ((props: P) => PointingDirection),
  sizeArg: string | ((props: P) => string),
  chevronColors: ThemeValues<P>
) {
  const colorLookup = themedValue(chevronColors)
  return function themeLookup(props: DigitsThemeProps & P) {
    const pointingDirection =
      typeof pointingDirectionArg === "function"
        ? pointingDirectionArg(props)
        : pointingDirectionArg

    const size = typeof sizeArg === "function" ? sizeArg(props) : sizeArg

    return chevronStyles(pointingDirection, size, colorLookup(props))
  }
}

export const chevronStyles = (
  pointingDirection: PointingDirection,
  size = "3px",
  color = colors.white
) => {
  let borderStyles: RuleSet

  switch (pointingDirection) {
    case PointingDirection.Up:
      borderStyles = css`
        border-bottom-color: ${color};
        border-top: none;
      `
      break
    case PointingDirection.Right:
      borderStyles = css`
        border-left-color: ${color};
        border-right: none;
      `
      break
    case PointingDirection.Down:
      borderStyles = css`
        border-top-color: ${color};
        border-bottom: none;
      `
      break
    case PointingDirection.UpLeft:
      borderStyles = css`
        border-bottom-color: ${color};
        border-top: none;
      `
      break
    case PointingDirection.None:
      borderStyles = css``
      break
    default:
      borderStyles = css`
        border-right-color: ${color};
        border-left: none;
      `
      break
  }

  return css`
    border-top: ${size} solid ${colors.transparent};
    border-right: ${size} solid ${colors.transparent};
    border-bottom: ${size} solid ${colors.transparent};
    border-left: ${size} solid ${colors.transparent};

    ${borderStyles};

    content: "";
  `
}

export const PointingRightLinkChevron = styled(SvgChevronRight)`
  margin-left: 3px;
  width: 15px;
  height: 15px;
  align-self: flex-end;
  ${svgPathStyles(colors.translucentSecondary50, 1.5)};
  cursor: pointer;
  transition: transform 200ms ease;
`
