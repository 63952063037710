import { RetryLink } from "@apollo/client/link/retry"
import { buildDelayFunction } from "@apollo/client/link/retry/delayFunction"
import { buildRetryFunction } from "@apollo/client/link/retry/retryFunction"

/*
  Retry HTTP requests with backoff.

  See https://www.apollographql.com/docs/react/api/link/apollo-link-retry/ for more details.

  NOTE: This only will retry network errors returned during Apollo requests, not GraphQL errors
        returned in a response.
*/
export default () =>
  new RetryLink({
    attempts: buildRetryFunction({
      max: Infinity,
    }),
    delay: buildDelayFunction({
      initial: 1000,
      max: 60 * 1000,
      jitter: true,
    }),
  })
