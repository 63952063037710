import envHelper from "@digits-shared/helpers/envHelper"
import dayjs, { type Dayjs } from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import calendar from "dayjs/plugin/calendar"
import customParseFormat from "dayjs/plugin/customParseFormat"
import dayOfYear from "dayjs/plugin/dayOfYear"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import isLeapYear from "dayjs/plugin/isLeapYear"
import isoWeek from "dayjs/plugin/isoWeek"
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import localizedFormat from "dayjs/plugin/localizedFormat"
import objectSupport from "dayjs/plugin/objectSupport"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import weekday from "dayjs/plugin/weekday"
import weekOfYear from "dayjs/plugin/weekOfYear"

// Extend dayjs with plugins
dayjs.extend(advancedFormat)
dayjs.extend(calendar)
dayjs.extend(customParseFormat)
dayjs.extend(dayOfYear)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isLeapYear)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)
dayjs.extend(localizedFormat)
dayjs.extend(objectSupport)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)

dayjs.locale("en-US")

if (envHelper.isDevelopment() && !envHelper.isTest()) {
  import("dayjs/plugin/devHelper").then(({ default: devHelper }) => {
    dayjs.extend(devHelper)
  })
}

export default dayjs
export type { Dayjs }
