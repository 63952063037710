import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

const H1_TEXT_STYLES = css`
  font-size: 24px;
  line-height: 33px;
  font-weight: 900;
`

const H2_TEXT_STYLES = css`
  font-size: 18px;
  line-height: 25px;
  font-weight: 900;
`

const BODY_TEXT_STYLES = css`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
`

const DETAIL_TEXT_STYLES = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`

const LABEL_TEXT_STYLES = css`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
`

const fonts = {
  family: {
    avenir: "'Avenir', Helvetica, Arial, sans-serif",
    amithen: "Amithen, Helvetica, Arial, sans-serif",
    monospace: {
      courierNew: "'Courier Prime', 'Courier New', Courier, monospace",
    },
  },
  weight: {
    light: 300,
    book: 350,
    normal: 400,
    roman: 400,
    medium: 500,
    heavy: 800,
    black: 900,
  },
  style: {
    normal: "normal",
    oblique: "oblique",
  },
  scale: {
    h1: H1_TEXT_STYLES,
    h2: H2_TEXT_STYLES,
    body: BODY_TEXT_STYLES,
    detail: DETAIL_TEXT_STYLES,
    label: LABEL_TEXT_STYLES,
  },
}
export default fonts

interface TextProps {
  color?: string
  weight?: "light" | "book" | "normal" | "roman" | "medium" | "heavy" | "black"
}

export const H1Text = styled.div<TextProps>`
  ${H1_TEXT_STYLES};
  font-weight: ${({ weight }) => (weight ? fonts.weight[weight] : fonts.weight.black)};
  color: ${({ color }) => (color ? color : colors.secondary)};
`

export const H2Text = styled.div<TextProps>`
  ${H2_TEXT_STYLES};
  font-weight: ${({ weight }) => (weight ? fonts.weight[weight] : fonts.weight.black)};
  color: ${({ color }) => (color ? color : colors.secondary)};
`

export const BodyText = styled.div<TextProps>`
  ${BODY_TEXT_STYLES};
  font-weight: ${({ weight }) => (weight ? fonts.weight[weight] : fonts.weight.roman)};
  color: ${({ color }) => (color ? color : colors.secondary)};
`

export const DetailText = styled.div<TextProps>`
  ${DETAIL_TEXT_STYLES};
  font-weight: ${({ weight }) => (weight ? fonts.weight[weight] : fonts.weight.roman)};
  color: ${({ color }) => (color ? color : colors.secondary)};
`

export const LabelText = styled.label<TextProps>`
  ${LABEL_TEXT_STYLES};
  font-weight: ${({ weight }) => (weight ? fonts.weight[weight] : fonts.weight.roman)};
  color: ${({ color }) => (color ? color : colors.secondary)};
`
