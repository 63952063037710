import * as React from "react"
import { SessionContext } from "@digits-shared/components/Contexts/SessionContext"
import { type JWTSession } from "@digits-shared/session/jwt/jwtSession"
import type Session from "@digits-shared/session/Session"

/**
 * Returns a `Session` to access data from the JWT
 *
 * @typeparam T Session type
 */
export default function useSession<T extends Session<JWTSession>>(): T {
  const session = React.useContext(SessionContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => session as T, [session])
}
