import * as React from "react"
import { useEvent } from "react-use"

type Callback = (visibilityState: DocumentVisibilityState) => void

/**
 * This hook sets up callback function to be called when the document visibility state changes
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the component's lifecycle.
 */
export default function useVisibilityChange(callback: Callback) {
  const cb = React.useRef(callback)

  React.useEffect(() => {
    cb.current = callback
  }, [callback])

  const onChange = React.useCallback(() => cb.current?.(document.visibilityState), [])
  useEvent("visibilitychange", onChange)
}

/** Returns the current document VisibilityState */
export function useDocumentVisibilityState(): DocumentVisibilityState {
  const [state, setState] = React.useState(document.visibilityState)

  const visibilityChanged = React.useCallback((visibilityState: DocumentVisibilityState) => {
    setState(visibilityState)
  }, [])

  useVisibilityChange(visibilityChanged)

  return state
}

/** Returns true if the document is currently visible; false otherwise */
export function useDocumentVisible(): boolean {
  const state = useDocumentVisibilityState()

  return state === "visible"
}
