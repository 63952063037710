import * as React from "react"
import { Lottie } from "@digits-shared/components/UI/Lottie/Lottie"
import { type ButtonSize } from "@digits-shared/DesignSystem/Button"
import styled from "styled-components"

const sizes: Record<ButtonSize, number> = {
  small: 11,
  medium: 14,
  default: 16,
}

const Center = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

// extra specificity is needed to prevent button level overrides
const Loader = styled(Lottie)<{ size: ButtonSize }>`
  height: ${({ size }) => sizes[size]}px;
  svg {
    position: unset !important;
    margin-left: 0 !important;
  }
`

const Crib = styled.div`
  display: contents;
  visibility: hidden;
`

/**
 * Renders a centered loading animation if loading is true, otherwise renders children.
 *
 * Wrap your regular button text / icons in this component inside of a DigitsButton.
 *
 */
export const ButtonContent: React.FC<
  React.PropsWithChildren<{ loading: boolean; size: ButtonSize }>
> = ({ loading, size, children }) =>
  loading ? (
    <>
      <Center>
        <Loader
          aria-label="loading"
          animationData={require("shared/static/lottie/bubble-loader.json")}
          size={size}
        />
      </Center>
      <Crib aria-hidden>{children}</Crib>
    </>
  ) : (
    children
  )
