import * as React from "react"
import { Redirect, type RedirectProps } from "react-router-dom"
import envHelper from "@digits-shared/helpers/envHelper"
import errorHelper from "@digits-shared/helpers/errorHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"

interface Props extends Omit<RedirectProps, "from" | "exact"> {
  name: string
  error?: Error
}

/**
 * Use instead of <Redirect /> so we can understand where redirects are coming from.
 * In development, it logs the provided name and props to the console when the redirect
 * is applied. The browser console must be set to display verbose messages to see
 * the output.
 */
export const LoggedRedirect: React.FC<Props> = ({ name, error, ...redirectProps }) => {
  const loc = useRouter().location
  const { isDigitsEmployee } = useSession()

  if (!envHelper.isProduction() || isDigitsEmployee) {
    const errorMsg = errorHelper.errorMessage(error, true)
    // Use console.debug so that it's grouped under the "Verbose" level in dev tools
    // eslint-disable-next-line no-console
    console.debug(name, errorMsg, { from: loc.fullPathname, ...redirectProps })
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Redirect {...redirectProps} />
}
