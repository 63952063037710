// Doppelgangers by default will not have access to Digits Employee features to
// test them before customers have access. However for debugging purposes we will
// want to turn this on to investigate bugs that customers might not be yet experiencing.
import { storage } from "@digits-shared/helpers/storage/storage"

export enum AccessLevel {
  Customer = "Customer",
  Dashboard = "Dashboard",
  FullAccess = "FullAccess",
}

declare type AccessLevelType = keyof typeof AccessLevel

export default class DoppelgangerAccessLevel {
  static ACCESS_LEVEL_KEY = "digits.dg.level"

  private accessLevel = AccessLevel.FullAccess

  constructor() {
    const accessLevel = storage.session.getItem(
      DoppelgangerAccessLevel.ACCESS_LEVEL_KEY
    ) as AccessLevelType
    if (accessLevel && AccessLevel[accessLevel]) {
      this.accessLevel = AccessLevel[accessLevel]
    }
  }

  get hasCustomerAccess() {
    return this.accessLevel === AccessLevel.Customer || this.hasDashboardAccess
  }

  get hasDashboardAccess() {
    return this.accessLevel === AccessLevel.Dashboard || this.hasFullAccess
  }

  get hasFullAccess() {
    return this.accessLevel === AccessLevel.FullAccess
  }

  get level() {
    return this.accessLevel
  }

  set level(level: AccessLevel) {
    this.accessLevel = level
    storage.session.setItem(DoppelgangerAccessLevel.ACCESS_LEVEL_KEY, level)
  }
}
