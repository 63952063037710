import stringHelper from "./stringHelper"

const { isArray } = Array
const isObject = (o: unknown): o is object =>
  o === Object(o) && !isArray(o) && typeof o !== "function"

function keysOf<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[]
}
const hasProp = Object.prototype.hasOwnProperty

// Modification of `fast-deep-equal` to support ignore fields
export function deepEqual<T, K extends string>(a: T, b: T, ...ignoreFields: K[]) {
  if (a === b) return true

  if (a && b && typeof a === "object" && typeof b === "object") {
    const arrA = isArray(a)
    const arrB = isArray(b)
    let i: number
    let key: keyof T

    if (arrA && arrB) {
      const { length } = a as Array<T>
      if (length !== (b as Array<T>).length) return false

      for (i = length; i-- !== 0; ) {
        if (!deepEqual(a[i], b[i], ...ignoreFields)) return false
      }
      return true
    }

    if (arrA !== arrB) return false

    const dateA = (a as unknown) instanceof Date
    const dateB = (b as unknown) instanceof Date
    if (dateA !== dateB) return false
    if (dateA && dateB) return (a as unknown as Date).getTime() === (b as unknown as Date).getTime()

    const regexpA = (a as unknown) instanceof RegExp
    const regexpB = (b as unknown) instanceof RegExp
    if (regexpA !== regexpB) return false
    if (regexpA && regexpB)
      return (a as unknown as RegExp).toString() === (b as unknown as RegExp).toString()

    let keys = keysOf(a)
    const { length } = keys

    if (ignoreFields.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      keys = keys.filter((k) => ignoreFields.indexOf(k as any) < 0)
    }

    if (length !== keysOf(b as object).length) {
      return false
    }

    for (i = keys.length; i-- !== 0; ) {
      if (!hasProp.call(b, keys[i] as PropertyKey)) return false
    }

    for (i = length; i-- !== 0; ) {
      key = keys[i] as keyof T
      if (!deepEqual(a[key], b[key], ...ignoreFields)) return false
    }

    return true
  }

  // eslint-disable-next-line no-self-compare
  return a !== a && b !== b
}

export function shallowEqual<T>(a: T, b: T) {
  if (a === b) {
    return true
  }
  if (a && b && typeof a === "object" && typeof b === "object") {
    const keysA = keysOf(a)
    const keysB = keysOf(b)

    if (keysA.length !== keysB.length) {
      return false
    }

    const dateA = (a as unknown) instanceof Date
    const dateB = (b as unknown) instanceof Date
    if (dateA !== dateB) return false
    if (dateA && dateB) return (a as unknown as Date).getTime() === (b as unknown as Date).getTime()

    const regexpA = (a as unknown) instanceof RegExp
    const regexpB = (b as unknown) instanceof RegExp
    if (regexpA !== regexpB) return false
    if (regexpA && regexpB)
      return (a as unknown as RegExp).toString() === (b as unknown as RegExp).toString()

    const isEqual = Object.is
    const hasOwn = Object.prototype.hasOwnProperty

    for (let i = 0; i < keysA.length; i += 1) {
      const key = keysA[i] as keyof T
      if (!hasOwn.call(b, key as PropertyKey) || !isEqual(a[key], b[key])) {
        return false
      }
    }

    return true
  }
  // eslint-disable-next-line no-self-compare
  return a !== a && b !== b
}

export function cloneDeep<T extends object>(val: T) {
  return cloneDeepHelper<T>(val)
}

function cloneDeepHelper<T>(val: T, seen?: Map<unknown, unknown>): T {
  switch (Object.prototype.toString.call(val)) {
    case "[object Array]": {
      // eslint-disable-next-line no-param-reassign
      seen = seen || new Map()
      if (seen.has(val)) return seen.get(val) as T
      const copy = (val as unknown as T[]).slice(0)
      seen.set(val, copy)
      copy.forEach(function forEach(child, i) {
        copy[i] = cloneDeepHelper(child, seen)
      })
      return copy as unknown as T
    }

    case "[object Object]": {
      // eslint-disable-next-line no-param-reassign
      seen = seen || new Map()
      if (seen.has(val)) return seen.get(val) as T
      // High fidelity polyfills of Object.create and Object.getPrototypeOf are
      // possible in all JS environments, so we will assume they exist/work.
      const copy = Object.create(Object.getPrototypeOf(val))
      seen.set(val, copy)
      Object.keys(val as object).forEach((key) => {
        copy[key] = cloneDeepHelper(val[key as keyof T], seen)
      })
      return copy
    }

    default:
      return val
  }
}

export function pluck<T extends object, K extends keyof T>(
  obj: T,
  ...propertyNames: K[]
): Pick<T, K> {
  return propertyNames
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {}) as Pick<T, K>
}

export function reject<T extends object, K extends keyof T>(
  obj: T,
  ...rejectedNames: K[]
): Omit<T, K> {
  const keys = isObject(obj) ? keysOf(obj) : []
  return keys
    .filter((k) => !rejectedNames.includes(k as K))
    .map((k) => ({ [k]: obj[k] }))
    .reduce((res, o) => Object.assign(res, o), {}) as Omit<T, K>
}

export function keysToCamel<T>(o: T): T {
  if (isArray(o)) {
    return o.map((i: T) => keysToCamel(i)) as T
  }

  if (isObject(o)) {
    const n: Record<string, unknown> = {}
    keysOf(o).forEach((k) => {
      n[stringHelper.camelCase(k.toString())] = keysToCamel(o[k])
    })
    return n as T
  }

  return o
}

function compareObjectNames<T extends { name: string }>(A: T, B: T) {
  if (A.name === undefined && B.name === undefined) return 0
  if (A.name === undefined) return 1
  if (B.name === undefined) return -1
  return A.name.toLocaleLowerCase().localeCompare(B.name.toLocaleLowerCase())
}

export default {
  keysOf,
  keysToCamel,
  deepEqual,
  shallowEqual,
  cloneDeep,
  pluck,
  reject,
  compareObjectNames,
}
