import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { type DigitsThemeProps, themedValue, type ThemeValues } from "@digits-shared/themes/index"

export function themedSVGIconStyles<P>(colors: ThemeValues<P>, transition?: boolean) {
  const colorLookup = themedValue(colors)
  return function themeLookup(props: DigitsThemeProps & P) {
    return svgIconStyles(colorLookup(props), transition)
  }
}

export function themedSVGPathStyles<P>(
  colors: ThemeValues<P>,
  stroke?: number,
  transition?: boolean
) {
  const colorLookup = themedValue(colors)
  return function themeLookup(props: DigitsThemeProps & P) {
    return svgPathStyles(colorLookup(props), stroke, transition)
  }
}
