export enum GRPCErrorCode {
  // OK is returned on success.
  OK = "OK",

  // Canceled indicates the operation was canceled (typically by the caller).
  Canceled = "Canceled",

  // Unknown error. An example of where this error may be returned is
  // if a Status value received from another address space belongs to
  // an error-space that is not known in this address space. Also
  // errors raised by APIs that do not return enough error information
  // may be converted to this error.
  Unknown = "Unknown",

  // InvalidArgument indicates client specified an invalid argument.
  // Note that this differs from FailedPrecondition. It indicates arguments
  // that are problematic regardless of the state of the system
  // (e.g., a malformed file name).
  InvalidArgument = "InvalidArgument",

  // DeadlineExceeded means operation expired before completion.
  // For operations that change the state of the system, this error may be
  // returned even if the operation has completed successfully. For
  // example, a successful response from a server could have been delayed
  // long enough for the deadline to expire.
  DeadlineExceeded = "DeadlineExceeded",

  // NotFound means some requested entity (e.g., file or directory) was
  // not found.
  NotFound = "NotFound",

  // AlreadyExists means an attempt to create an entity failed because one
  // already exists.
  AlreadyExists = "AlreadyExists",

  // PermissionDenied indicates the caller does not have permission to
  // execute the specified operation. It must not be used for rejections
  // caused by exhausting some resource (use ResourceExhausted
  // instead for those errors). It must not be
  // used if the caller cannot be identified (use Unauthenticated
  // instead for those errors).
  PermissionDenied = "PermissionDenied",

  // ResourceExhausted indicates some resource has been exhausted, perhaps
  // a per-user quota, or perhaps the entire file system is out of space.
  ResourceExhausted = "ResourceExhausted",

  // FailedPrecondition indicates operation was rejected because the
  // system is not in a state required for the operation's execution.
  // For example, directory to be deleted may be non-empty, an rmdir
  // operation is applied to a non-directory, etc.
  FailedPrecondition = "FailedPrecondition",

  // Aborted indicates the operation was aborted, typically due to a
  // concurrency issue like sequencer check failures, transaction aborts,
  // etc.
  Aborted = "Aborted",

  // OutOfRange means operation was attempted past the valid range.
  // E.g., seeking or reading past end of file.
  OutOfRange = "OutOfRange",

  // Unimplemented indicates operation is not implemented or not
  // supported/enabled in this service.
  Unimplemented = "Unimplemented",

  // Internal errors. Means some invariants expected by underlying
  // system has been broken. If you see one of these errors,
  // something is very broken.
  Internal = "Internal",

  // Unavailable indicates the service is currently unavailable.
  // This is a most likely a transient condition and may be corrected
  // by retrying with a backoff. Note that it is not always safe to retry
  // non-idempotent operations.
  Unavailable = "Unavailable",

  // DataLoss indicates unrecoverable data loss or corruption.
  DataLoss = "DataLoss",

  // Unauthenticated indicates the request does not have valid
  // authentication credentials for the operation.
  Unauthenticated = "Unauthenticated",
}
