import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import { createGlobalStyle, css } from "styled-components"

function viewportDimensions() {
  const onResize = () => {
    const doc = document.documentElement
    doc.style.setProperty("--viewport-height", `${window.innerHeight}px`)
  }
  window.addEventListener("resize", onResize)
  onResize()

  return undefined
}

const globalResponsiveStyles = responsiveStyles({
  desktop: css`
    html,
    body,
    main {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100vh;
      min-height: 100vh;
      min-width: 100vw;
      overscroll-behavior-x: none;
      overscroll-behavior-y: none;
    }
  `,
  mobile: css`
    ${viewportDimensions};

    html,
    body,
    main {
      width: 100vw;
      min-width: 100vw;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: hidden;
    }

    body {
      height: -webkit-fill-available;
    }

    main {
      height: var(--viewport-height);
      width: var(--viewport-width);
      overflow-y: auto;
    }
  `,
})

const mainResponsivePadding = responsiveStyles({
  desktop: undefined,
  mobile: css`
    main {
      padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
        env(safe-area-inset-left);
    }
  `,
  tablet: css`
    @media only screen and (orientation: landscape) {
      main {
        padding: env(safe-area-inset-top) 25% env(safe-area-inset-bottom) 25%;
      }
    }

    @media only screen and (orientation: portrait) {
      main {
        padding: env(safe-area-inset-top) 15% env(safe-area-inset-bottom) 15%;
      }
    }
  `,
})

export const GlobalStyles = createGlobalStyle`
  :root {
    --viewport-height: -webkit-fill-available;
    --viewport-width: -webkit-fill-available;
  }
  
  ${globalResponsiveStyles};
  ${mainResponsivePadding};

  * {
    box-sizing: border-box; 
  }
  
  body, b, button, input, select {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: inherit;
  }

  input {
    border-radius: 0;
  }

  input:focus {
    outline: 0;
  }

  @-webkit-keyframes autofill {
    0%,100% {
      color: inherit;
      background: inherit;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
  
  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  b {
    font-weight: 700;
  }

  ul, ol {
    margin: 0;
    padding: 0;
  }

  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: normal;
    font-size: unset;
  }
`
