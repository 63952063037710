import { type TypePolicies } from "@apollo/client"
import { mergeIdentifiers } from "src/shared/initializers/typePolicies/helpers/mergeIdentifiers"

export const reportPackage: TypePolicies = {
  ReportPackage: {
    keyFields: ["id", "versionId"],
    fields: {
      versions: {
        merge: false,
      },
    },
  },

  ReportPackageMetadata: {
    keyFields: ["id", "versionId"],
    fields: {
      versions: {
        merge: mergeIdentifiers,
      },
    },
  },

  ReportAutomationSettings: {
    keyFields: ["organizationId", "legalEntityId", "interval"],
  },
}
