export enum Keys {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Enter = "Enter",
  Tab = "Tab",
  Escape = "Escape",
  Comma = ",",
  Minus = "-",
  Period = ".",
  Plus = "+",
  Space = "Space",
  Backspace = "Backspace",
  Shift = "Shift",
  Meta = "Meta",
  PageUp = "PageUp",
  PageDown = "PageDown",
  Delete = "Delete",
  BracketRight = "BracketRight",
  BracketLeft = "BracketLeft",
}

export function isNavigationEscape(event: KeyboardEvent) {
  const target = event.target as HTMLElement
  // valid Escape as long as it is not cursor not focused on a text field
  return event.key === Keys.Escape && !["INPUT", "TEXTAREA"].includes(target.tagName?.toUpperCase())
}
