import { type Reference } from "@apollo/client"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { mergeArrays } from "src/shared/initializers/typePolicies/helpers/mergeReducers"

export function mergeObjectEntities(existing: Reference | undefined, incoming: Reference) {
  const merged = objectHelper.cloneDeep(existing || incoming)

  objectHelper.keysOf(merged).forEach((key) => {
    if (!Array.isArray(merged[key])) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    merged[key] = mergeArrays(merged[key] || [], incoming[key] || [])
  })

  return merged
}
