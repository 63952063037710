import { ApolloLink } from "@apollo/client"

export default (neverBatch = false) =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        ...operation.getContext().headers,
        "x-batch-request": !neverBatch && !operation.getContext().noBatch,
      },
    })

    return forward(operation) || null
  })
