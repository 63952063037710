import { exists } from "@digits-shared/helpers/typehelper"

type EventCallback = (...args: unknown[]) => void
/**
 * Create an event emitter with namespaces
 */
export default class EventEmitter {
  callbackRegistry: { [key: string]: EventCallback[] } = {}

  /**
   * Emit an event. Optionally namespace the event. Handlers are fired in the order in which they
   * were added with exact matches taking precedence. Separate the namespace and event with a `:`
   * @name emit
   * @param {String} event – the name of the event, with optional namespace
   * @param {...*} args – arguments that are passed to the event listener
   * @example
   * emitter.emit('example')
   * emitter.emit('demo:test')
   * emitter.emit('data', { example: true}, 'a string', 1)
   */
  emit(event: string, ...args: unknown[]) {
    const toEmit = this.getListeners(event)

    if (toEmit.length) {
      this.emitAll(event, toEmit, args)
    }
  }

  /**
   * Create en event listener.
   * @name on
   * @param {String} event
   * @param {Function} fn
   * @example
   * emitter.on('example', function () {})
   * emitter.on('demo', function () {})
   */
  on(event: string, fn: EventCallback) {
    if (!this.callbackRegistry[event]) {
      this.callbackRegistry[event] = []
    }

    this.callbackRegistry[event]?.push(fn)
  }

  /**
   * Create en event listener that fires once.
   * @name once
   * @param {String} event
   * @param {Function} fn
   * @example
   * emitter.once('example', function () {})
   * emitter.once('demo', function () {})
   */
  once(event: string, fn: EventCallback) {
    const one = (...args: unknown[]) => {
      fn.apply(this, args)
      this.off(event, one)
    }

    this.on(event, one)
  }

  /**
   * Stop listening to an event. Stop all listeners on an event by only passing the event name. Stop
   * a single listener by passing that event handler as a callback. You must be explicit about what
   * will be unsubscribed: `emitter.off('demo')` will unsubscribe an `emitter.on('demo')` listener,
   * `emitter.off('demo:example')` will unsubscribe an `emitter.on('demo:example')` listener
   * @name off
   * @param {String} event
   * @param {Function} functionToRemove – the specific handler
   * @example
   * emitter.off('example')
   * emitter.off('demo', function () {})
   */
  off(event: string, functionToRemove?: EventCallback) {
    let keep: EventCallback[] = []

    if (event && functionToRemove) {
      const fns = this.callbackRegistry[event] as EventCallback[] | undefined

      if (fns) keep = fns.filter((fn) => fn !== functionToRemove)
    }

    keep.length ? (this.callbackRegistry[event] = keep) : delete this.callbackRegistry[event]
  }

  private getListeners(eventToBeFired: string) {
    // Find all the namespaces of the event to be fired
    const eventParts = eventToBeFired.split(":")

    // Get all the possible events that could be fired.
    // Example: "first:second:third" -> ["first", "first:second", "first:second:third"]
    let namespaceIndex = 0
    const eventsToFire: string[] = []

    while (namespaceIndex < eventParts.length) {
      let nextNamespaceIndex = 0
      let eventName = eventParts[nextNamespaceIndex]

      while (nextNamespaceIndex < namespaceIndex) {
        eventName = `${eventName}:${eventParts[nextNamespaceIndex + 1]}`
        nextNamespaceIndex += 1
      }

      eventsToFire.push(eventName || "")
      namespaceIndex += 1
    }

    const callbacks = eventsToFire.flatMap((eventName) => this.callbackRegistry[eventName] || [])

    // If there are wildcard callbacks, appending them to the start so they are called first
    return this.callbackRegistry["*"] ? this.callbackRegistry["*"].concat(callbacks) : callbacks
  }

  private emitAll(event: string, functions: EventCallback[], args: unknown[]) {
    let i = 0
    const l = functions.length

    for (i; i < l; i += 1) {
      const fn = functions[i]
      if (!exists(fn)) break
      fn.apply(fn, args)
    }
  }
}
