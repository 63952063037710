export class DeferredPromise<T = unknown> {
  promise: Promise<T>
  resolve: (value: T) => void
  reject: (error?: unknown) => void

  isPending = true
  isResolved = false
  isRejected = false

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    }).then(
      (v) => {
        this.isPending = false
        this.isResolved = true
        return v
      },
      (e) => {
        this.isPending = false
        this.isRejected = true
        throw e
      }
    )
  }
}
