// Do not key off interval's index key.
// We want to cache responses by all the filters except these in order to support infinite scroll
import { type FieldFunctionOptions } from "@apollo/client"
import { DirectionFromOrigin, type IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { allKeyArgs } from "./allKeyArgs"
import { type ExistsFn, reducerAppend, reducerPrepend } from "./mergeReducers"

interface IntervalVars {
  origin: IntervalOrigin
  direction: DirectionFromOrigin
}

const EXCLUDED_KEYS = ["direction"]

const intervalOriginKeyArgs = (args: IntervalVars) => allKeyArgs(args, EXCLUDED_KEYS)

export function intervalOriginPagination<TVars extends IntervalVars = IntervalVars, TData = object>(
  exists?: ExistsFn<TData>
) {
  return {
    keyArgs: intervalOriginKeyArgs,
    merge(existing: TData[], incoming: TData[], options: FieldFunctionOptions<TVars>) {
      const { args } = options
      // not an array, unsupported (you have to do your own merge function)
      if (!existing?.slice) {
        return incoming
      }

      const direction = args?.direction || DirectionFromOrigin.Past

      // If we are reducing a request from the past, we want to put the elements at the end.
      // Otherwise its a future request, so they should be prepended to keep the correct order
      const reducer =
        direction === DirectionFromOrigin.Past ? reducerAppend(exists) : reducerPrepend(exists)

      return incoming.reduce(reducer, existing.slice(0))
    },
  }
}
