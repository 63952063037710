import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

export default {
  radius: {
    default: 2,
    input: 3,
    highlight: 8,
    card: 12,
    pill: 12,
    tooltip: 12,
    large: 20,
    extraLarge: 50,
  },

  dropShadow: `0px 2px 4px 0px ${colors.theme.light.boxShadow}`,
  menuShadow: `0px 3px 8px 0px ${colors.theme.light.boxShadow}`,
  tableHeader: "2px solid #ddd",

  optionPopup: {
    shadow: `0 0 10px ${colors.translucentBlack10}`,
    border: `1px solid ${colors.translucentWhite10}`,
    radius: 16,
  },

  theme: {
    light: {
      divider: {
        top: lightDivider("top"),
        bottom: lightDivider("bottom"),
      },
    },
    dark: {
      radius: {
        listItem: 3,
        modal: 8,
        dropdown: 14,
      },
      modalBoxShadow: `0 0 100px ${colors.theme.dark.boxShadow}`,
      buttonBoxShadow: `0px 0px 20px ${colors.translucentNeonGreen30}`,
      textShadow: `0px 0px 20px ${colors.translucentNeonGreen70}`,
      textShortShadow: `0px 0px 4px ${colors.translucentNeonGreen70}`,
      selectedIndicatorShadow: `inset 4px 0px ${colors.neonGreen}`,
      selectedWithNextIndicatorShadow: `inset -4px 0px ${colors.neonGreen}`,
      selectedContainer: (insetBorder = false) => css`
        background-color: rgba(24, 78, 58, 0.01);
        ${insetBorder
          ? css`
              box-shadow:
                inset 0 0 30px ${colors.theme.dark.interactedContainerGlow},
                inset 0 0 0 1px ${colors.theme.dark.selectedContainerBorder};
            `
          : css`
              box-shadow: inset 0 0 30px ${colors.theme.dark.interactedContainerGlow};
              border: 1px solid ${colors.theme.dark.selectedContainerBorder};
            `};
      `,
      divider: {
        top: darkDivider("top"),
        bottom: darkDivider("bottom"),
        topAndBottom: darkDivider("topAndBottom"),
        right: darkDivider("right"),
        left: darkDivider("left"),
      },
    },
  },
}

function lightDivider(position: "top" | "bottom" | "topAndBottom") {
  return css`
    ${position === "top"
      ? `border-top: 1px solid ${colors.lightGray};`
      : `border-bottom: 1px solid ${colors.lightGray};`}
  `
}

function darkDivider(position: "top" | "bottom" | "topAndBottom" | "right" | "left") {
  const horizontalShared = css`
    border: none;
    border-image: linear-gradient(
      to right,
      ${colors.transparent} 0px,
      ${colorHelper.hexToRgba(colors.white, 0.01)} 5px,
      rgba(37, 109, 136, 0.7) 50%,
      ${colorHelper.hexToRgba(colors.white, 0.01)} calc(100% - 5px),
      ${colors.transparent} 100%
    );
    border-image-slice: 1;
  `

  const verticalShared = css`
    border: none;
    border-image: linear-gradient(
      to top,
      ${colors.transparent} 0px,
      ${colorHelper.hexToRgba(colors.white, 0.01)} 5px,
      rgba(37, 109, 136, 0.7) 50%,
      ${colorHelper.hexToRgba(colors.white, 0.01)} calc(100% - 5px),
      ${colors.transparent} 100%
    );
    border-image-slice: 1;
  `

  switch (position) {
    case "bottom":
      return css`
        ${horizontalShared};
        border-bottom: 1px solid ${colors.transparent};
        border-top-width: 0;
        border-image-width: 0 0 1px 0;
      `
    case "top":
      return css`
        ${horizontalShared};
        border-top: 1px solid ${colors.transparent};
        border-bottom-width: 0;
        border-image-width: 1px 0 0 0;
      `
    case "topAndBottom":
      return css`
        ${horizontalShared};
        border-top: 1px solid ${colors.transparent};
        border-bottom: 1px solid ${colors.transparent};
        border-image-width: 1px 0 1px 0;
      `
    case "right":
      return css`
        ${verticalShared};
        border-right: 1px solid ${colors.transparent};
        border-left-width: 0;
        border-image-width: 0 1px 0 0;
      `
    case "left":
      return css`
        ${verticalShared};
        border-left: 1px solid ${colors.transparent};
        border-right-width: 0;
        border-image-width: 0 0 0 1px;
      `
  }
}
