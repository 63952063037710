// eslint-disable-next-line no-restricted-imports
import {
  type QueryListAgentPartiesArgs,
  type QueryListCsvImportsArgs,
  type QueryListCsvImportTransactionFactsArgs,
  type QueryListGlobalPartiesArgs,
  type QueryListInstitutionsArgs,
  type QueryListLegalEntitiesArgs,
  type QueryListOrganizationsArgs,
  type QueryListPartiesArgs,
  type QueryListPartyExtractionsArgs,
  type QueryListSignupsArgs,
} from "@digits-graphql/admin/graphql-bearer"
import {
  type QueryListDocumentsArgs,
  type QueryListMetricTransactionsArgs,
  type QueryListTransactionsArgs,
  type QuerySearchEntitiesArgs,
} from "@digits-graphql/frontend/graphql-bearer"
import { offsetLimitPagination } from "src/shared/initializers/typePolicies/helpers"
import { listInsights } from "./listInsights"

export const queries = {
  listGlobalPartiesWithSummaries: offsetLimitPagination<QueryListGlobalPartiesArgs>(),
  listInsights,
  listLegalEntities: offsetLimitPagination<QueryListLegalEntitiesArgs>(),
  listParties: offsetLimitPagination<QueryListPartiesArgs>(),
  listSignups: offsetLimitPagination<QueryListSignupsArgs>(),
  listTeams: { merge: false },
  listEmployees: { merge: false },

  searchEntities: offsetLimitPagination<QuerySearchEntitiesArgs>(),

  listTransactions: offsetLimitPagination<QueryListTransactionsArgs>(),
  listMetricTransactions: offsetLimitPagination<QueryListMetricTransactionsArgs>(),

  listInstitutions: offsetLimitPagination<QueryListInstitutionsArgs>(),
  listOrganizations: offsetLimitPagination<QueryListOrganizationsArgs>(),
  listApprovalPriorityOrganizations: offsetLimitPagination<QueryListOrganizationsArgs>(),

  readAssociatedThreads: { merge: false },
  listWorkItems: { merge: false },

  summarizeInvoiceGroups: { merge: false },

  listAgentParties: offsetLimitPagination<QueryListAgentPartiesArgs>(),
  listPartyExtractions: offsetLimitPagination<QueryListPartyExtractionsArgs>(),

  listCsvImports: offsetLimitPagination<QueryListCsvImportsArgs>(),
  listCsvImportTransactionFacts: offsetLimitPagination<QueryListCsvImportTransactionFactsArgs>(),

  listDocuments: offsetLimitPagination<QueryListDocumentsArgs>(),
}
