import { type TypePolicies } from "@apollo/client"

export const layout: TypePolicies = {
  Layout: {
    keyFields: ["layoutId"],
    fields: {
      sections: {
        merge: false,
      },
    },
  },
  LayoutSection: {
    keyFields: ["sectionId"],
    fields: {
      rows: {
        merge: false,
      },
    },
  },
  LayoutRow: {
    keyFields: ["rowId"],
    fields: {
      components: {
        merge: false,
      },
    },
  },
  LayoutComponent: {
    keyFields: ["componentId"],
  },
}
