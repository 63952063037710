// initialize TrackJS as soon as Apollo is set up
// eslint-disable-next-line
import "@digits-shared/initializers/trackJs"
import { ApolloClient, ApolloLink, type FetchPolicy, HttpLink, split } from "@apollo/client"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { type HttpOptions } from "@apollo/client/link/http/selectHttpOptionsAndBody"
import { getMainDefinition } from "@apollo/client/utilities"
import { type GraphQLTrace } from "@digits-shared/components/Contexts/GraphQLTracerContext"
import envHelper from "@digits-shared/helpers/envHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import WSLink from "@digits-shared/initializers/apollo/middleware/ws/wsLink"
import httpBatch from "@digits-shared/initializers/apollo/middleware/http/batch"
import httpBreakVersion from "@digits-shared/initializers/apollo/middleware/http/breakingVersion"
import httpError from "@digits-shared/initializers/apollo/middleware/http/error"
import httpGraphQLTrace from "@digits-shared/initializers/apollo/middleware/http/graphQLTrace"
import httpRetry from "@digits-shared/initializers/apollo/middleware/http/retry"
import httpTokenUpdate from "@digits-shared/initializers/apollo/middleware/http/tokenUpdate"
import wsAuth from "@digits-shared/initializers/apollo/middleware/ws/auth"
import wsRetry from "@digits-shared/initializers/apollo/middleware/ws/retry"
import type Session from "@digits-shared/session/Session"
import { Kind, OperationTypeNode } from "graphql"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"
import { cache } from "src/shared/initializers/apollo/apolloCache"
import frontendAuth from "src/shared/initializers/apollo/frontendAuth"

interface ApolloClientOptions {
  defaultFetchPolicy?: FetchPolicy
  neverBatch?: boolean
  withWS?: boolean
  onTrace?: (trace: GraphQLTrace) => void
  bearerGraphqlEndpoint?: string
  payGraphqlEndpoint?: string
  publicGraphqlEndpoint?: string
}

export default function useSetupApolloClient<S extends Session>(
  session: S,
  options?: ApolloClientOptions
) {
  return useConstant(() => {
    const {
      defaultFetchPolicy = "cache-first",
      neverBatch = (session.isDigitsEmployee &&
        session.getBooleanUserPreference(userPreferenceKeys.graphqlNoBatching)) ||
        false,
      withWS = false,
      bearerGraphqlEndpoint = process.env.GRAPHQL_ENDPOINT,
      payGraphqlEndpoint = process.env.GRAPHQL_PAY_ENDPOINT,
      publicGraphqlEndpoint = process.env.GRAPHQL_PUBLIC_ENDPOINT,
    } = options || {}

    const httpOptions: HttpOptions = {
      uri: bearerGraphqlEndpoint,

      // Enable cross-origin referrers for apollo originated requests,
      // which will only occur to the above-configured uri. This enables
      // greatly improved debug visibility into the specific page the user
      // was on when an error was generated.
      fetchOptions: { referrerPolicy: "no-referrer-when-downgrade" },
    }

    const singleHttpRequestLink = new HttpLink(httpOptions)
    const batchHttpRequestLink = new BatchHttpLink(httpOptions)
    const avsBatchHttpRequestLink = new BatchHttpLink(httpOptions)

    // Dedicate link chain for HTTP requests
    const httpLinkChain = ApolloLink.from([
      // Error first, so any downstream issues are handled
      httpError(session),
      // Then retry, so any failures downstream are retried
      httpRetry(),
      frontendAuth(session, { bearerGraphqlEndpoint, payGraphqlEndpoint, publicGraphqlEndpoint }),
      httpBreakVersion(),
      httpBatch(neverBatch),
      httpGraphQLTrace(session, options?.onTrace),
      httpTokenUpdate(session),
      split(
        (operation) => operation.getContext().noBatch === true || neverBatch,
        singleHttpRequestLink,
        split(
          (operation) => operation.getContext().avsBatch === true,
          avsBatchHttpRequestLink,
          batchHttpRequestLink
        )
      ),
    ])

    // Dedicate link chain for WS requests.
    const wsLinkChain = ApolloLink.from([
      httpError(session),
      wsRetry(session),
      // Auth after retry so that retries always have a valid token.
      wsAuth(session),
      new WSLink(session, withWS, process.env.GRAPHQL_WEBSOCKET_ENDPOINT || ""),
    ])

    const defaultOptions = {
      watchQuery: { fetchPolicy: defaultFetchPolicy },
    }

    return new ApolloClient({
      assumeImmutableResults: envHelper.isProduction() || envHelper.isStaging(),

      // If the connection request is a subscription, use wsLink. Otherwise, use http link chain.
      link: split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === Kind.OPERATION_DEFINITION &&
            definition.operation === OperationTypeNode.SUBSCRIPTION
          )
        },
        // Temporary workaround to suppress errors caused when a Digits Employee logs in.
        // This causes them to send a subscription operation even though the network client was
        // initialized without WS support.
        withWS ? wsLinkChain : ApolloLink.empty(),
        httpLinkChain
      ),
      cache,
      defaultOptions,
    })
  })
}
