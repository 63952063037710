import * as React from "react"
import { type RouteComponentProps, Router } from "react-router-dom"
import { type Routes, type StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import { type DigitsHistory } from "@digits-shared/components/Router/History"
import {
  createBrowserHistory,
  createMemoryHistory,
  type MemoryHistoryBuildOptions,
  type UnregisterCallback,
} from "history"
import { type DigitsLocation, mutateLocationWithDigitsProperties } from "./DigitsLocation"

export interface DigitsRouteProps extends RouteComponentProps<Record<string, string>> {
  history: DigitsHistory
  location: DigitsLocation
}

export interface DigitsRouterProps<SRC extends StaticRoutes> {
  routes: Routes<SRC>
  children?: React.ReactNode
}

/**
 * Sets custom properties needed on the Location object used throughout the webapp. Performs set
 * in history listen callback so it occurs outside of the render method and is available prior
 * to a render with React Router properties.
 */
export class DigitsRouter<SRC extends StaticRoutes, P = {}> extends React.Component<
  DigitsRouterProps<SRC> & P
> {
  history: DigitsHistory

  private currentLocation: DigitsLocation | undefined
  protected unregisterHistoryListener: UnregisterCallback

  constructor(props: DigitsRouterProps<SRC> & P, history: DigitsHistory) {
    super(props)
    this.history = history
    mutateLocationWithDigitsProperties(history.location, props.routes)
  }

  componentDidMount() {
    this.unregisterHistoryListener = this.history.listen(this.onHistoryLocationChange)
    this.onHistoryLocationChange(this.history.location as DigitsLocation)
  }

  componentWillUnmount() {
    this.unregisterHistoryListener()
  }

  render() {
    const { children } = this.props
    return <Router history={this.history}>{children}</Router>
  }

  onHistoryLocationChange = (location: DigitsLocation) => {
    const { routes } = this.props

    // Append some custom Digits properties to the location. This must be a mutation because we
    // want to modify the location reference on the history object rather than reassigning.
    // Reassigning will break how react router uses history location under the covers.
    mutateLocationWithDigitsProperties(location, routes)

    // The location is changing. Store the current location as the previous location on the history
    // only if the current location we are tracking is different that the location we are going to.
    if (
      !this.history.previousLocation ||
      this.currentLocation?.fullPathname !== location.fullPathname
    ) {
      this.history.previousLocation = this.currentLocation
    }

    // Set the previous location as the current location now that we have properly referenced the
    // actual previous location on the history object above
    this.currentLocation = location
  }
}

/**
 * DigitsRouter that is configured with a browser History object which keeps the router
 * in-sync with the browser URL state
 */
export class DigitsBrowserRouter<SRC extends StaticRoutes, P = {}> extends DigitsRouter<SRC, P> {
  constructor(props: DigitsRouterProps<SRC> & P) {
    const { routes } = props
    const browserHistory = createBrowserHistory() as DigitsHistory
    mutateLocationWithDigitsProperties(browserHistory.location, routes)
    super(props, browserHistory)
  }
}

/**
 * DigitsRouter that is configured with a memory History object which tracks routing history
 * but does not connect it with the browser URL state
 */
export class DigitsMemoryRouter<SRC extends StaticRoutes, P = {}> extends DigitsRouter<
  SRC,
  MemoryHistoryBuildOptions & P
> {
  constructor(props: DigitsRouterProps<SRC> & MemoryHistoryBuildOptions & P) {
    const { routes, ...memoryRouterProps } = props
    const memoryHistory = createMemoryHistory(memoryRouterProps) as unknown as DigitsHistory
    mutateLocationWithDigitsProperties(memoryHistory.location, routes)
    super(props, memoryHistory)
  }
}
