import * as React from "react"
import { isDigitsMacApp, isFirefox } from "@digits-shared/helpers/devicesHelper"
import { themedValue } from "@digits-shared/themes"
import { DIGITS_BACKGROUND } from "@digits-shared/themes/background"
import colors from "@digits-shared/themes/colors"
import { GRADIENT_HALO } from "@digits-shared/themes/gradientHalo"
import fonts from "@digits-shared/themes/typography"
import { createGlobalStyle, css } from "styled-components"
import { GlobalStyles as SharedGlobalStyles } from "@digits-shared/initializers/globalStyles"

export const SCROLLBAR_WIDTH = 6

function addScrollBarStyles(color: string, borderColor: string) {
  const size = () => {
    const fakeElem = document.createElement("div")
    fakeElem.style.width = "100px"
    fakeElem.style.height = "100px"
    fakeElem.style.overflow = "scroll"
    document.body.append(fakeElem)
    const val = fakeElem.offsetWidth - fakeElem.clientWidth
    fakeElem.remove()
    return val
  }

  // if size is 0, use native styles
  if (!size()) return undefined

  return css`
    *::-webkit-scrollbar-button,
    *::-webkit-scrollbar-corner {
      display: none;
    }

    *::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0);
      width: ${SCROLLBAR_WIDTH}px;
      height: ${SCROLLBAR_WIDTH}px;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${color};
      border: 1px solid ${borderColor};
      border-radius: ${SCROLLBAR_WIDTH / 2}px;
    }

    ${isFirefox &&
    css`
      scrollbar-color: ${color} ${colors.transparent};
      scrollbar-width: thin;
    `};
  `
}

function addDigitsForMacStyles() {
  if (!isDigitsMacApp) return undefined

  // Custom styles for Digits for Mac.
  // Disables selecting text for certain elements
  return css`
    #springboard-header,
    #application-dock,
    #springboard-footer {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
    }

    input,
    textarea,
    div[contenteditable="true"] {
      -webkit-touch-callout: default;
      -webkit-user-select: auto;
    }
  `
}

const globalStyles = themedValue({
  light: css`
    color: ${colors.secondary};
    ${DIGITS_BACKGROUND};
    ${addScrollBarStyles(colors.translucentSecondary10, colors.translucentSecondary10)}
  `,
  dark: css`
    color: ${colors.white};
    background-color: #000;
    background-image: radial-gradient(55% 60% at 50% 50%, #061d2b 0%, rgba(0, 209, 255, 0) 100%),
      linear-gradient(115deg, #0f2342 0%, #010609 100%);

    background-size: cover;
    background-repeat: no-repeat;
    ${addScrollBarStyles("#04060d", "#394152")}
  `,
})
const AccountingGlobalStyles = createGlobalStyle`
  ${addDigitsForMacStyles};

  :root {
    font-family: ${fonts.family.avenir};
    font-weight: ${fonts.weight.normal};
    
    ${globalStyles};
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
`

export const GlobalStyles: React.FC = () => (
  <>
    <SharedGlobalStyles />
    <AccountingGlobalStyles />
  </>
)

export const GlowStyleOverride = createGlobalStyle`
  :root {
    ${GRADIENT_HALO};
  }
`
