import { isMobile, isTablet } from "@digits-shared/helpers/devicesHelper"
import { type RuleSet } from "styled-components"

export enum SizeClass {
  Mobile = "mobile",
  Desktop = "desktop",
  Tablet = "tablet",
}

type InterpolationFromProps<P, R> = (props: P) => R

type SizeValue<P = object, R = string> = R | InterpolationFromProps<P, R>

export type SizeValues<P = object, R = string> = {
  [SizeClass.Desktop]: SizeValue<P, R>
  [SizeClass.Mobile]: SizeValue<P, R>
  [SizeClass.Tablet]?: SizeValue<P, R>
}

function isInterpolationFunction<P, R>(
  it: R | InterpolationFromProps<P, R>
): it is InterpolationFromProps<P, R> {
  return typeof it === "function"
}

function getResponsiveValue<P, R>(props: P, values: SizeValues<P, R>): R {
  let sizeValue: SizeValue<P, R>
  if (isTablet && values.tablet) {
    sizeValue = values.tablet
  } else if (isMobile) {
    sizeValue = values.mobile
  } else {
    sizeValue = values.desktop
  }

  if (isInterpolationFunction(sizeValue)) {
    return sizeValue(props)
  }
  return sizeValue
}

/**
 * Accepts an object that provides values for keys matching each size class defined in the ResponsiveSize enum.
 * Returns a function suitable for direct use in a styled-components string interpolation ( ${...} )
 *
 * When used in a styled-components string, it will determine the currently active size class,
 * and return the value for that key, which can be used directly in your CSS.
 *
 * Example:
 *
 * const paddingLeft = responsiveValue({
 *   desktop: "10px",
 *   mobile: "5px",
 * })
 *
 * export const SummarySubtitle = styled.span`
 *   font-weight: ${fonts.weight.normal};
 *   font-size: 13px;
 *   padding-left: ${paddingLeft};
 * `
 */
export function responsiveValue<P = object, R = string>(values: SizeValues<P, R>) {
  return function sizeLookUp(props: P) {
    return getResponsiveValue(props, values)
  }
}

/**
 * Accepts an object whose keys match each size class defined in the ResponsiveSize enum. Each value should be
 * CSS styles created with the styled-components css`` decorator. Returns a function suitable for
 * direct use in a styled-components string interpolation ( ${...} )
 *
 * When used in a styled-components string, it will determine the currently active size class,
 * and return the styles for that key, which can be used directly in your CSS.
 *
 * Example:
 *
 * const segmentResponsiveStyles = responsiveStyles({
 *   desktop: css`
 *     width: 100px;
 *     min-width: 1200px;
 *   `,
 *   mobile: css`
 *     width: 100vw;
 *   `,
 * })
 *
 * const Segment = styled.div`
 *   ${segmentResponsiveStyles};
 *   font-size: 13px;
 * `
 */
export function responsiveStyles<P extends object = object>(
  values: SizeValues<P, RuleSet<P> | undefined | false>
) {
  return function sizeLookUp(props: P) {
    return getResponsiveValue(props, values)
  }
}
