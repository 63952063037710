import { setContext } from "@apollo/client/link/context"
import { authorizationBearer } from "@digits-shared/session/authorizationBearer"
import type Session from "@digits-shared/session/Session"

// Since there are no headers for websocket connections, we will pass the authentication via the
// GraphQL extensions field.
export default (session: Session) =>
  setContext((request) =>
    session.bearer().then((token) => {
      // Must assign as we want to modify the request extensions object directly. Any reassignment
      // will result in the change no being carried through in the network request.
      if (request.extensions) {
        Object.assign(request.extensions, authorizationBearer(token))
      }
      return request
    })
  )
