export default {
  hexToRgba(hex: string, alpha = 1) {
    if (hex.match(/^rgba/)) return hex

    const rgbCodes = hex.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )

    if (rgbCodes) {
      const values = rgbCodes.substring(1).match(/.{2}/g)
      if (values) {
        const rgbArray = values.map((x) => parseInt(x, 16))
        return `rgba(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`
      }
      throw new Error("hex passed is not a valid rgba")
    }

    throw new Error("hex passed is not a valid rgba")
  },

  rgbToHex(r: number, g: number, b: number) {
    const hexValues = [r, g, b]
      .map((x) => {
        const hex = x.toString(16)
        return hex.length === 1 ? `0${hex}` : hex
      })
      .join("")

    return `#${hexValues}`
  },
}
