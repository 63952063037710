import * as React from "react"
import { useKey } from "react-use"
import { type UseKeyOptions } from "react-use/lib/useKey"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"

export function useEscapeKeyCapture(
  onEscapePressed?: (e: KeyboardEvent) => void,
  useKeyOptions?: Omit<UseKeyOptions<HTMLElement>, "event">
) {
  const onReactElementKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      const { key } = e
      if (key === Keys.Escape && onEscapePressed) {
        e.preventDefault()
        e.stopPropagation()
        onEscapePressed(e.nativeEvent)
      }
    },
    [onEscapePressed]
  )

  const onBodyKeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (onEscapePressed) {
        e.preventDefault()
        e.stopPropagation()
        onEscapePressed(e)
      }
    },
    [onEscapePressed]
  )

  useKey(Keys.Escape, onBodyKeydown, { event: "keydown", ...useKeyOptions })

  return onReactElementKeydown
}
