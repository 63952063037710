import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { ApolloError, useApolloClient } from "@apollo/client"
import { type TokenRefreshMutationFn } from "@digits-graphql/frontend/graphql-public"
import envHelper from "@digits-shared/helpers/envHelper"
import errorHelper from "@digits-shared/helpers/errorHelper"
import useSession from "@digits-shared/hooks/useSession"
import BearerRefreshManager from "@digits-shared/session/BearerRefreshManager"
import { GraphQLError } from "graphql"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

export default function useSessionTokenRefresh(
  tokenRefreshMutation: TokenRefreshMutationFn,
  isAdmin = false
) {
  const client = useApolloClient()
  const session = useSession<FrontendSession>()
  const activeRefreshMutationRef = React.useRef(false)

  useMutateSessionActiveLegalEntity(session)
  // get the activeLegalEntityId after the mutation hook in case it needs to be changed
  const activeLegalEntityId = session.lastKnownLegalEntityId

  const onFetch = React.useCallback(
    (skipNotifyTokenUpdate = false) => {
      // Don't attempt to fetch if the user is logged out
      if (!session.hasUserData) {
        // Need to clear the refresh active flag in the session and notify listeners
        session.bearerRefreshSkipped()
        return
      }
      // Don't attempt to fetch if another request is active
      if (activeRefreshMutationRef.current) {
        // Flag clearing and notification is handled by the in-flight request
        return
      }

      activeRefreshMutationRef.current = true
      tokenRefreshMutation({
        variables: {
          activeLegalEntityId,
        },
        context: {
          publicAPI: true,
          skipNotifyTokenUpdate,
        },
      })
        .then((results) => {
          if (results.errors) throw new ApolloError({ graphQLErrors: results.errors })
          if (!results.data) {
            throw new ApolloError({
              graphQLErrors: [new GraphQLError("No data returned from token refresh mutation")],
            })
          }

          activeRefreshMutationRef.current = false
          // do not reset bearer token if there is no user data (logged-out user)
          // this hook can be called right after the user has logged out which will log them back in.
          if (!session.hasUserData) return
          return session.onBearerChange(results.data.tokenRefresh, skipNotifyTokenUpdate)
        })
        .catch((error: ApolloError) => {
          // unauthenticated is expected because the session could have expired. Only track error if something else
          if (!errorHelper.isUnauthenticated(error)) {
            TrackJS?.track(error)
          }
          activeRefreshMutationRef.current = false
          error.graphQLErrors?.forEach((e) => {
            session.bearerFetchError(e)
          })
        })
    },
    [activeLegalEntityId, session, tokenRefreshMutation]
  )

  // Needs to be a layout effect so that the listener is registered immediately/synchronously.
  // Otherwise, it's possible for the listener to be registered _after_ an Apollo request is
  // queued. That pending request triggers a blocking token refresh, but this handler can't
  // react if the listener is not yet registered. The queued calls are then deadlocked.
  React.useLayoutEffect(() => {
    session.on(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, onFetch)
    session.on(BearerRefreshManager.TOKEN_NON_BLOCKING_STARTED_EVENT_NAME, onFetch)

    return () => {
      session.off(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, onFetch)
      session.off(BearerRefreshManager.TOKEN_NON_BLOCKING_STARTED_EVENT_NAME, onFetch)
    }
  }, [session, onFetch])

  React.useEffect(() => {
    session.blockingBearerRefresh()

    // ignore playwright
    if (envHelper.isTest() || envHelper.isMocks() || isAdmin) return
    // reset cache only when switching LEs, not every time token is refreshed
    session.bearer().then(async () => {
      // Remove all data from the store. clearStore will not refetch any active queries.
      await client.clearStore()
      client.cache.gc() // Garbage collection to remove unreferenced data
    })
  }, [activeLegalEntityId, client, isAdmin, session])
}

/**
 * Updates the active legal entity in the session based on the `leSlug` parameter from the URL.
 * This allows the session to adjust its active legal entity when navigating to different legal entity.
 *
 */
function useMutateSessionActiveLegalEntity(session: FrontendSession) {
  const params = useRouteMatch<{ leSlug?: string }>(routes.legalEntity.parameterizedPath)?.params
  const urlLESlug = params?.leSlug
  const currentLESlug = session.currentLegalEntity?.slug

  // If the `leSlug` from the URL is different from the current legal entity's slug in the session,
  // attempt to find and set the new legal entity in the session
  if (urlLESlug && urlLESlug !== currentLESlug) {
    const entity = session.findLegalEntityBySlug(urlLESlug)
    // Update the session's active legal entity if a matching entity is found
    if (entity) {
      session.currentLegalEntity = entity
    }
  }
}
