import { TrackJS } from "trackjs"
import { v4 as generateUUID } from "uuid"

const versionElement = document.querySelector("meta[name=version]") as HTMLMetaElement

/**
 * Setup for the Tracker script before it has loaded
 */

// Only enable in production (not including staging). Need to ensure staging flag
// is not true because staging runs as node env production to build libraries in prod.
const enabled = process.env.NODE_ENV === "production" && process.env.STAGING !== "true"

// Do not track non-production network errors
const networkEnabled = enabled && process.env.GRAPHQL_ENDPOINT?.split("/").pop() === "graphql"

const SENSITIVE_QUERY_PARAMS = ["ml", "tok", "t"]

function redactUrl(inputUrl?: string): string {
  let url: URL
  try {
    url = new URL(inputUrl || "")
  } catch {
    return inputUrl || ""
  }

  SENSITIVE_QUERY_PARAMS.forEach((param) => url.searchParams.delete(param))
  return url.href
}

TrackJS.install({
  enabled,
  network: {
    enabled: networkEnabled,
    error: networkEnabled,
  },
  token: "55a37693e013428bbebfc231b466cf5a",
  sessionId: generateUUID(),
  version: (versionElement && versionElement.content) || Date.now().toString(),
  onError: (payload, _) => {
    payload.url = redactUrl(payload.url)
    return true
  },
})
TrackJS.addMetadata("client-version", process.env.CURRENT_VERSION || "")

window.TrackJS = TrackJS
