import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export interface HeaderProps {
  transform?: string
}

export const H2 = styled.h2<HeaderProps>`
  font-weight: ${fonts.weight.black};
  font-size: 16px;
  text-transform: ${(props) => props.transform || "uppercase"};
`

export const H3 = styled.h3<HeaderProps>`
  margin-bottom: 10px;
  text-transform: ${(props) => props.transform || "uppercase"};
  font-family: ${fonts.family.avenir};
  font-size: 13px;
`

export const H4 = styled.h4<HeaderProps>`
  text-transform: ${(props) => props.transform || "uppercase"};
  font-family: ${fonts.family.avenir};
  font-size: 10px;

  b {
    font-weight: ${fonts.weight.medium};
  }
`
