import { type FieldFunctionOptions } from "@apollo/client"
import { type ObjectEntities, type SearchHit } from "@digits-graphql/frontend/graphql-bearer"
import { uniqueBy } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"

export const searchResult = {
  SearchResult: {
    fields: {
      results: {
        merge(existing: SearchHit[], incoming: SearchHit[], { readField }: FieldFunctionOptions) {
          if (!existing) return incoming
          return [...existing, ...incoming].filter(
            uniqueBy((hit) => readField<string>("id", hit.objectId))
          )
        },
      },
      entities: {
        merge(existing: ObjectEntities, incoming: ObjectEntities) {
          if (!existing) return incoming
          return objectHelper.keysOf(incoming).reduce(
            (mergedEntities, entityName) => {
              const prevEntities = existing[entityName]
              const newEntities = incoming[entityName]
              if (typeof prevEntities === "string") return mergedEntities

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              mergedEntities[entityName] = (prevEntities || []).concat(newEntities || [])
              return mergedEntities
            },
            { ...incoming }
          )
        },
      },
    },
  },
}
