import { type DigitsTheme, Theme } from "@digits-shared/themes/theme"
import { type RuleSet } from "styled-components"

export type DigitsThemeProps = { theme?: DigitsTheme }

type InterpolationFromProps<P, R> = (props: P) => R

type ThemeValue<P = object, R = string> = R | InterpolationFromProps<P, R>

export type ThemeValues<P = object, R = string> = {
  [Theme.Light]: ThemeValue<P, R>
  [Theme.Dark]: ThemeValue<P, R>
  [Theme.Print]?: ThemeValue<P, R>
}

function isInterpolationFunction<P, R>(
  it: R | InterpolationFromProps<P, R>
): it is InterpolationFromProps<P, R> {
  return typeof it === "function"
}

function getThemeValue<P, R>(props: P & DigitsThemeProps, values: ThemeValues<P, R>): R {
  const mode = props.theme?.mode
  let themeValue: ThemeValue<P, R>
  if (mode === Theme.Print) {
    themeValue = values[Theme.Print] ?? values[Theme.Light]
  } else {
    themeValue = values[mode || Theme.Light]
  }

  if (isInterpolationFunction(themeValue)) {
    return themeValue(props)
  }
  return themeValue
}

/**
 * Accepts an object that provides values for keys matching each theme defined in the Theme enum.
 * Returns a function suitable for direct use in a styled-components string interpolation ( ${...} )
 *
 * When used in a styled-components string, it will determine the currently active theme from the
 * props it receives, and return the value for that key, which can be used directly in your CSS.
 *
 * Example:
 *
 * const subtitleColor = themedValue({
 *   light: colors.black,
 *   dark: colors.white,
 * })
 *
 * export const SummarySubtitle = styled.span`
 *   font-weight: ${fonts.weight.normal};
 *   font-size: 13px;
 *   color: ${subtitleColor};
 * `
 */
export function themedValue<P = object, R = string>(values: ThemeValues<P, R>) {
  return function themeLookup(props: DigitsThemeProps & P) {
    return getThemeValue(props, values)
  }
}

/**
 * Accepts an object whose keys match each theme defined in the Theme enum. Each value should be
 * CSS styles created with the styled-components css`` decorator. Returns a function suitable for
 * direct use in a styled-components string interpolation ( ${...} )
 *
 * When used in a styled-components string, it will determine the currently active theme from the
 * props it receives, and return the styles for that key, which can be used directly in your CSS.
 *
 * Example:
 *
 * const segmentThemeStyles = themedStyles({
 *   light: css`
 *     background-color: ${colors.white};
 *     box-shadow: ${borders.dropShadow};
 *     border-radius: ${borders.radius.default}px;
 *   `,
 *   dark: css`
 *     background-color: ${colors.transparent};
 *     border-bottom: 1px solid ${colors.translucentLighterWhite};
 *   `,
 * })
 *
 * const Segment = styled.div`
 *   ${segmentThemeStyles};
 *   font-size: 13px;
 * `
 */
export function themedStyles<P extends object = object>(
  values: ThemeValues<P, RuleSet<P> | undefined | false>
) {
  return function themeLookup(props: DigitsThemeProps & P) {
    return getThemeValue(props, values)
  }
}
