import { type ReportPackageDocumentMetadata } from "@digits-graphql/frontend/graphql-bearer"

export const reportPackageDocument = {
  ReportPackageDocument: {
    fields: {
      documents: {
        merge(
          existing: ReportPackageDocumentMetadata[],
          incoming: ReportPackageDocumentMetadata[]
        ) {
          if (!existing?.length) return incoming

          const merged = existing.slice()
          const existingById = new Map<string, ReportPackageDocumentMetadata>()
          merged.reduce(
            (existingMap, nextExisting) => existingMap.set(nextExisting.id, nextExisting),
            existingById
          )

          incoming.forEach(
            (nextIncoming) => existingById.has(nextIncoming.id) && merged.push(nextIncoming)
          )

          return merged
        },
      },
    },
  },
}
