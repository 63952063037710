import * as React from "react"
import type Session from "@digits-shared/session/Session"

/**
 * Required as the default value for the create context. Needed for testing because instantiating
 * a new Session calls to `localStorage` which in some test cases is not set up yet when this file
 * is loaded. Session should be set via the `SessionContext.Provider` API at webapp entry point.
 */
class NoopSession {}

/**
 * Context Consumer and Provider for Session
 */
export const SessionContext = React.createContext<Session>(new NoopSession() as Session)

export interface SessionProps<S extends Session = Session> {
  session: S
}
