import colorHelper from "@digits-shared/helpers/colorHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { type DigitsThemeProps, themedValue } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import { type Property } from "csstype"
import styled, { css, keyframes } from "styled-components"
import { type CSSProperties } from "styled-components/dist/types"

const LOADER_SHINE = keyframes`
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 400% 0;
  }
`

export interface LoadingBlockProps {
  width: string | number
  height: string | number
  margin?: string | number
  textAlign?: Property.TextAlign
  display?: Property.Display
  backgroundColor?: Property.BackgroundColor
  borderRadius?: Property.BorderRadius
  animationPlayState?: Property.AnimationPlayState
  $shineColor?: string
  $randomWidthRange?: number
}

const propsToStyle = (props: DigitsThemeProps & LoadingBlockProps) => {
  let { width, height, margin } = props
  if (typeof width === "number") {
    width = `${width}px`
  }
  if (typeof height === "number") {
    height = `${height}px`
  }
  if (typeof margin === "number") {
    margin = `${margin}px`
  }

  if (props.$randomWidthRange) {
    const unitParts = numberHelper.parseUnit(width)
    if (!isNaN(unitParts.value)) {
      const randomWidth = Math.floor(Math.random() * props.$randomWidthRange)
      width = `${unitParts.value - randomWidth}${unitParts.unit}`
    }
  }

  const display = props.display || "inline-block"
  const textAlign = props.textAlign || undefined
  const borderRadius = props.borderRadius || `${borders.radius.default}px`
  const animationPlayState = props.animationPlayState || undefined

  const customColor = props.backgroundColor
  const backgroundColor =
    customColor ||
    themedValue({
      light: colors.white,
      dark: colors.translucentWhite04,
    })(props)

  const customShineColor = props.$shineColor
  const shineColor =
    customShineColor ||
    themedValue({
      light: colors.translucentBlack10,
      dark: colors.translucentWhite20,
    })(props)

  const background = `${backgroundColor} linear-gradient(to right, ${colors.transparent} 0, ${shineColor} 50%, ${colors.transparent} 100%) no-repeat`
  const backgroundSize = "50% 100%"
  const opacity = 0.8

  return {
    style: {
      width,
      height,
      margin,
      display,
      textAlign,
      borderRadius,
      opacity,
      background,
      backgroundSize,
      animationPlayState,
    } as CSSProperties,
  }
}

export const LoadingBlock = styled.div.attrs<LoadingBlockProps>(propsToStyle)<LoadingBlockProps>`
  position: relative;
  overflow: hidden;
  animation: ${LOADER_SHINE} 0.75s linear infinite;
`

export const LoadingCircle = styled(LoadingBlock).attrs<LoadingBlockProps>((props) => {
  const attrs = propsToStyle(props)

  let { width } = props
  if (typeof width === "number") {
    width = `${width}px`
  }
  const parts = numberHelper.parseUnit(width)
  attrs.style.clipPath = `circle(${parts.value / 2}${parts.unit} at center)`
  attrs.style.WebkitClipPath = `circle(${parts.value / 2}${parts.unit} at center)`
  attrs.style.borderRadius = "100%"
  return attrs
})``

const AI_LOADER_SHINE = keyframes`
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: 150% 0;
  }
`

export const AILoadingBlock = styled.div<{ height?: number; width?: number }>`
  background: linear-gradient(91deg, #dde8f4 7.6%, #aec5de 39.27%, #dde8f4 67.24%);
  background-size: 50% 100%;
  border-radius: 8px;
  height: ${({ height = 24 }) => `${height}px`};
  width: ${({ width }) => (typeof width !== "undefined" ? `${width}px` : "100%")};
  animation: ${AI_LOADER_SHINE} 1.5s linear infinite;
`

const GRADIENT_LOADER_SHINE = keyframes`
  0% {
    left: -150%;
  }

  50% {
    left: 75%;
  }

  100% {
    left: 100%;
  }
`

export const GradientLoadingBlock = styled.div<{
  height?: number
  width?: number
  borderRadius?: string | number
  gradient?: string
  shineColor?: string
}>`
  --ai-gradient: linear-gradient(
    84deg,
    rgba(57, 156, 212, 0.06) -4.07%,
    rgba(57, 156, 212, 0.3) 13.78%,
    rgba(94, 91, 233, 0.3) 74.27%,
    rgba(145, 91, 233, 0.3) 95.1%
  );

  position: relative;
  overflow: hidden;

  background: ${({ gradient }) => gradient || "var(--ai-gradient)"} no-repeat;

  height: ${({ height = 24 }) => `${height}px`};
  width: ${({ width }) => (typeof width !== "undefined" ? `${width}px` : "100%")};
  border-radius: ${({ borderRadius = 8 }) =>
    typeof borderRadius !== "undefined" ? `${borderRadius}px` : borderRadius};

  &::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;

    ${({ shineColor = colors.white }) => css`
      background: linear-gradient(
        90deg,
        ${colorHelper.hexToRgba(shineColor, 0)} 16%,
        ${colorHelper.hexToRgba(shineColor, 0.8)} 46%,
        ${colorHelper.hexToRgba(shineColor, 0.8)} 54%,
        ${colorHelper.hexToRgba(shineColor, 0)} 84%
      );
    `};
    animation: ${GRADIENT_LOADER_SHINE} 2s infinite;
  }
`
