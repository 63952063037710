import { ApolloLink } from "@apollo/client"

export default () =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        ...operation.getContext().headers,
        // this process.env.${CURRENT,BREAKING}_VERSION variables are built
        // into the app at compile time via vite, and is intended to
        // remain static for the lifetime of this app version.
        // Current version is used for debugging which version
        // of the client is running.
        "x-client-version": process.env.CURRENT_VERSION,
        // Breaking Version is used to determine if a hard refresh
        // should be triggered on error.
        "x-breaking-version": process.env.BREAKING_VERSION,
      },
    })

    return forward(operation) || null
  })
