import useRouter from "@digits-shared/hooks/useRouter"

export const PLG_APP_PATH_PARAM = "plgApp" as const

export enum PlgApp {
  UNKNOWN = "UNKNOWN",
  DIGITS = "DIGITS",
}

export enum PlgAppPathParams {
  DIGITS = "digits",
}

export const PLG_APP_PATH_PARAMS = [PlgAppPathParams.DIGITS] as const

export function usePlgApp() {
  const { match } = useRouter()
  const plgApp = match.params[PLG_APP_PATH_PARAM]
  return plgPathParamToApp(plgApp)
}

function plgPathParamToApp(plgPathParam: string | undefined) {
  switch (plgPathParam) {
    case PlgAppPathParams.DIGITS:
      return PlgApp.DIGITS
    default:
      return PlgApp.UNKNOWN
  }
}
