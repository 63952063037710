import { ApolloLink, type FetchResult, Observable, type Operation } from "@apollo/client"
import errorHelper from "@digits-shared/helpers/errorHelper"
import { authorizationBearer } from "@digits-shared/session/authorizationBearer"
import type Session from "@digits-shared/session/Session"
import { print } from "graphql"
import { type Client, type ClientOptions, createClient, type ExecutionResult } from "graphql-ws"
import { type SubscriptionObserver } from "zen-observable/esm"

// How often we should periodically send an updated auth token over the WS connection to prove we
// are still authorized. Needs to be more frequent than the "authInterval" in shared/graphql/graphqlws/periodic.go.
const PERIOD_AUTH_INTERVAL_MS = 1000 * 60 * 5 // 5 minutes

const RESET_CONNECTION_ATTEMPTS_TIMEOUT_MS = 30 * 1000 // 30 seconds

// Close codes defined in RFC 6455, section 7.4. Protocol for WebSocket.
// See: https://tools.ietf.org/html/rfc6455#section-7.4.2
enum CloseCode {
  NormalClosure = 1000,
  GoingAway = 1001,
  ProtocolError = 1002,
  UnsupportedData = 1003,
  NoStatusReceived = 1005,
  AbnormalClosure = 1006,
  InvalidFramePayloadData = 1007,
  PolicyViolation = 1008,
  MessageTooBig = 1009,
  MandatoryExtension = 1010,
  InternalServerErr = 1011,
  ServiceRestart = 1012,
  TryAgainLater = 1013,
  TLSHandshake = 1015,

  // 4000-4999 code range interpretable only by custom implementations

  // Websocket close code that is returned by the server to indicate that a websocket connection has
  // been open for too long and is being gracefully closed. It is expected that the client will reconnect.
  // See shared/graphql/graphqlws/lifetime.go
  MaxLifetimeExceeded = 4350,
  Unauthenticated = 4401,
}

// Close codes that we don't care to error on when we receive.
const IGNORABLE_CLOSE_CODES = [
  CloseCode.NormalClosure,
  CloseCode.GoingAway,
  CloseCode.NoStatusReceived,
  // Abnormal close is not something we need to error on as it can happen whenever there is a
  // an interruption to the websocket connection (e.g. during server redeploys).
  CloseCode.AbnormalClosure,
  CloseCode.MaxLifetimeExceeded,
  CloseCode.Unauthenticated,
]

//
// Sub-protocol for GraphQL over WebSocket
//
// The string value may be modified as long as the corresponding reference to them is updated
// in with server code: go-services/shared/graphql/graphqlws/message.go. Modeled after
// existing MessageType recommended by Apollo as the standard for GraphQL as a subprotocol over WS.
// See https://github.com/enisdenjo/graphql-ws/blob/7cebbfe00dc3c360e80e8962f345a28743b49c1f/src/message.ts#L18
enum MessageType {
  Auth = "authenticate", // Client -> Server
}

interface Message {
  id?: string
  type: MessageType
  payload: Partial<Operation>
}

// WSLink wraps the graphql-ws client which manages the connection and messages transmission.
// Conforms to the ApolloLink class so it can be used in a link chain. Contains a periodic re-auth
// message submitter for when used for authenticated websocket connections.
export default class WSLink<S extends Session> extends ApolloLink {
  private session: S
  private client: Client
  private activeRequestCount = 0
  private publicAPI: boolean

  constructor(session: S, isEnabled: boolean, url: string, publicAPI: boolean = false) {
    super()
    this.session = session

    if (!isEnabled) return

    const options: ClientOptions = {
      url,
      lazy: false,
      // Don't retry within this link. Instead rely on the external retry links so that
      // we have an opportunity to re-auth if needed.
      retryAttempts: 0,
      on: {
        connected: this.setSocketEventListeners,
        closed: this.onClientError,
      },
      // No-op lazy error to prevent default behavior of console.error. `closed` listener below
      // should handle any issues when closing.
      onNonLazyError: () => {},
    }

    this.client = createClient(options)
    this.publicAPI = publicAPI
  }

  request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => this.subscribe(sink, operation))
  }

  subscribe(
    sink: SubscriptionObserver<ExecutionResult<FetchResult>>,
    operation: Operation
  ): () => void {
    this.activeRequestCount += 1
    const cleanUpConnectionListeners = this.configureOperationConnectionAttempts(operation)

    const unsubscribe = this.client.subscribe<FetchResult>(
      { ...operation, query: print(operation.query) },
      {
        next: sink.next.bind(sink),
        complete: () => {
          this.activeRequestCount -= 1
          sink.complete()
        },
        error: sink.error.bind(sink),
      }
    )

    // To be called when we finish our subscription as a clean up
    return () => {
      unsubscribe()
      cleanUpConnectionListeners()
    }
  }

  // Increment (or default to 1 if not set) the connection attempts on the operation context. This
  // value can be used by other links (e.g. `RetryLink`) to track the number of connection attempts
  // made for this specific operation. If the client connects and holds open for > 30 seconds,
  // then reset the connection attempts to 1. If the connection is lost under the 30 seconds timer, then
  // we will still increment our attempts (effectively causing retries to backoff). If we have held
  // the connection open for > 30 seconds, it's safe to assume the connection is solid so we can reset.
  // Links such as RetryLink that are tracking connection attempts won't reset their own internal counts
  // if the connection is fully established again, so this separately tracked value allows for us
  // to reset the retry attempts under certain conditions.
  private configureOperationConnectionAttempts(operation: Operation): () => void {
    operation.setContext({
      connectionAttempts: (operation.getContext().connectionAttempts || 0) + 1,
    })

    let resetAttemptsTimeout: NodeJS.Timeout
    const removeClientConnectedListener = this.client.on("connected", () => {
      resetAttemptsTimeout = setTimeout(() => {
        operation.setContext({ connectionAttempts: 1 })
      }, RESET_CONNECTION_ATTEMPTS_TIMEOUT_MS)
    })

    return () => {
      removeClientConnectedListener()
      if (resetAttemptsTimeout) clearTimeout(resetAttemptsTimeout)
    }
  }

  // Set any listeners on the socket once its open and ready.
  private setSocketEventListeners = (socket: WebSocket) => {
    const periodAuthInterval = this.periodicAuthInterval(socket)
    socket.addEventListener(
      "close",
      this.createCloseListenerForInterval(socket, periodAuthInterval)
    )
  }

  private periodicAuthInterval = (socket: WebSocket): NodeJS.Timeout =>
    setInterval(() => {
      if (this.publicAPI || !this.activeRequestCount) return

      this.session.bearer().then((token) => {
        if (!token) {
          socket.close(CloseCode.Unauthenticated)
          return
        }

        this.sendMessage(socket, {
          type: MessageType.Auth,
          payload: {
            extensions: authorizationBearer(token),
          },
        })
      })
    }, PERIOD_AUTH_INTERVAL_MS)

  // Creates a closure which is fired when a socket closes, cleans up any added
  // listeners as well as itself.
  private createCloseListenerForInterval = (
    socket: WebSocket,
    periodicAuthInterval: NodeJS.Timeout
  ) => {
    // Closure function so we can remove it as a listener once the connection closes.
    const closeListener = () => {
      clearInterval(periodicAuthInterval)
      socket.removeEventListener("close", closeListener)
    }

    return closeListener
  }

  // Send a message over the provided websocket.
  private sendMessage = (socket: WebSocket, message: Message) => {
    socket.send(JSON.stringify(message))
  }

  // Log unexpected errors.
  private onClientError = (error: unknown) => {
    // Client throws a non-close event error
    if (!(error instanceof CloseEvent)) {
      return TrackJS?.console.error(`wsLink client closed: ${errorHelper.stringifyError(error)}`)
    }

    // it is a close event that we don't want to ignore
    if (error instanceof CloseEvent && !IGNORABLE_CLOSE_CODES.includes(error.code)) {
      return TrackJS?.console.error("wsLink client closed", error)
    }
  }
}
