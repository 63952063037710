import { type SafeReadonly } from "@apollo/client/cache/core/types/common"
import { type FieldFunctionOptions } from "@apollo/client/cache/inmemory/policies"
import {
  type Feature,
  FeatureKind,
  type Format,
  FormatClass,
  type Segment,
} from "@digits-graphql/frontend/graphql-bearer"
import { type FeatureValue, type TextFeatureValue } from "@digits-graphql/frontend/graphql-pay"
import { mergeArrays } from "src/shared/initializers/typePolicies/helpers/mergeReducers"

export const vaultFields = {
  RawDocument: {
    fields: {
      formats: {
        merge: mergeArrays,
      },
      features: {
        merge: mergeArrays,
      },

      title: {
        read(_: SafeReadonly<string>, { readField, canRead }: FieldFunctionOptions) {
          const featuresRefs = readField<Feature[]>("features")
          if (!featuresRefs) return null

          const titleRef = featuresRefs.find(
            (f) => canRead(f) && readField("kind", f) === FeatureKind.Title
          )

          if (!titleRef) return null
          const value = readField<FeatureValue>("value", titleRef)
          return value && isTextFeatureValue(value) ? value.textValue : null
        },
      },

      fileName: {
        read(_: SafeReadonly<string>, { readField, canRead }: FieldFunctionOptions) {
          const sourceFormat = readField<Format>("sourceFormat")
          if (!sourceFormat) return null
          return readField<Segment[]>("segments", sourceFormat)?.[0]?.fileName ?? null
        },
      },

      fileSize: {
        read(_: SafeReadonly<number>, { readField, canRead }: FieldFunctionOptions) {
          const sourceFormat = readField<Format>("sourceFormat")
          if (!sourceFormat) return null
          return readField<Segment[]>("segments", sourceFormat)?.[0]?.fileSize ?? null
        },
      },

      mimeType: {
        read(_: SafeReadonly<number>, { readField, canRead }: FieldFunctionOptions) {
          const sourceFormat = readField<Format>("sourceFormat")
          if (!sourceFormat) return null
          return readField<Segment[]>("mimeType", sourceFormat) ?? null
        },
      },

      thumbnail: {
        read(_: SafeReadonly<number>, { readField, canRead }: FieldFunctionOptions) {
          const formatsRefs = readField<Format[]>("formats")
          if (!formatsRefs) return null

          const imageThumb = formatsRefs.find(
            // use ImageSmall as the preview image as ImageThumbnail is too blurry
            (f) => canRead(f) && readField("formatClass", f) === FormatClass.ImageSmall
          )
          if (!imageThumb) return null

          return readField<Segment[]>("segments", imageThumb)?.find((s) => s.sequence === 0) || null
        },
      },

      sourceFormat: {
        read(_: SafeReadonly<number>, { readField, canRead }: FieldFunctionOptions) {
          const sourceFormatId = readField<string>("sourceFormatId")
          const formatsRefs = readField<Format[]>("formats")

          if (!sourceFormatId || !formatsRefs) return null

          return (
            formatsRefs.find((f) => canRead(f) && readField("id", f) === sourceFormatId) || null
          )
        },
      },
    },
  },

  Format: {
    fields: {
      segments: {
        merge: false,
      },
    },
  },
}

function isTextFeatureValue(value: FeatureValue): value is TextFeatureValue {
  return (value as TextFeatureValue).textValue !== undefined
}
